import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Profile.css'; // Assuming you have a Profile.css for styling
import axios from "axios";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const Users = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const data = sessionStorage.getItem('userData');
        if (data) {
            setUserData(JSON.parse(data)); // Parse the stored JSON string
        }
    }, []);

    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('adminEmail');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from the Express API
    useEffect(() => {
        axios
            .get('https://lionsnewsapi.emedha.in/superAdmin/userData')
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError('An error occurred while fetching data');
                setLoading(false);
            });
    }, []);
    console.log(data)

    const itemsPerPage = 10;

    // State to manage the current page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Get the data for the current page
    const currentData = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    return (
        <>
            <div className="sidebar-nav">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>Profile</button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="profile-content">
                <Container className="mt-4">
                    <Row>
                        <Col>
                            <h1 className="mb-4">Users Details</h1>
                            <div>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Profile Pic</th>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>District</th>
                                            <th>Club</th>
                                            <th>Membership No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>
                                                    <img
                                                        src={item.profilePic}
                                                        alt="Profile"
                                                        className="img-fluid"
                                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                    />
                                                </td>
                                                <td>{item.username}</td>
                                                <td>{item.mobile}</td>
                                                <td>{item.email}</td>
                                                <td>{item.district}</td>
                                                <td>{item.club}</td>
                                                <td>{item.membershipNo}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {/* Pagination */}
                                <Pagination>
                                    <Pagination.Prev
                                        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                    />
                                    {[...Array(totalPages)].map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1}
                                            active={index + 1 === currentPage}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                    />
                                </Pagination>
                                <span>
                                        Page <strong>{currentPage }</strong> of <strong>{totalPages}</strong>
                                    </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Users;
