// import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
// import './Profile.css'; // Assuming you have a Profile.css for styling
// import axios from "axios";

// const Admins = () => {
//     const navigate = useNavigate();
//     const [userData, setUserData] = useState(null);

//     useEffect(() => {
//         const data = sessionStorage.getItem('userData');
//         if (data) {
//             setUserData(JSON.parse(data)); // Parse the stored JSON string
//         }
//     }, []);

//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     const [admins, setAdmins] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Fetch the data from the Express API
//     useEffect(() => {
//         axios.get('https://lionsnewsapi.emedha.in/superAdmin/adminsignup')
//             .then(response => {
//                 setAdmins(response.data);  // Update state with data
//                 setLoading(false);  // Stop loading
//             })
//             .catch(err => {
//                 setError('Error fetching data');
//                 setLoading(false);
//             });
//     }, []); 
//     console.log(admins)

//     return (
//         <>
//             <div className="sidebar-nav">
//                 <h2>Dashboard</h2>
//                 <ul>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>Profile</button></li>
//                     <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
//                 </ul>
//             </div>

//             <div className="profile-content">
//                 <h1>Admins Details</h1>

//                 <div>
//             <h2>Admin Signup List</h2>
//             <table>
//                 <thead>
//                     <tr>
//                         <th>ID</th>
//                         <th>Username</th>
//                         <th>Email</th>
//                         <th>District</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {admins.map(admin => (
//                         <tr key={admin.id}>
//                             <td>{admin.id}</td>
//                             <td>{admin.name}</td>
//                             <td>{admin.email}</td>
//                             <td>{admin.district}</td>
//                             <td>{admin.created_at}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//             </div>
//         </>
//     );
// }

// export default Admins;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Col, Container, Pagination, Row, Table } from "react-bootstrap";

// const Admins = () => {
//     const navigate = useNavigate();
//     const [userData, setUserData] = useState(null);

//     useEffect(() => {
//         const data = sessionStorage.getItem('userData');
//         if (data) {
//             setUserData(JSON.parse(data)); // Parse the stored JSON string
//         }
//     }, []);

//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     const [admins, setAdmins] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Fetch the data from the Express API
//     useEffect(() => {
//         axios.get('https://lionsnewsapi.emedha.in/superAdmin/adminsignup')
//             .then(response => {
//                 setAdmins(response.data);  // Update state with data
//                 setLoading(false);  // Stop loading
//             })
//             .catch(err => {
//                 setError('Error fetching data');
//                 setLoading(false);
//             });
//     }, []);

//     // Split the columns into chunks of 10 columns per page
//     const getChunks = (data, chunkSize) => {
//         const result = [];
//         if (data && data.length > 0) {
//             const keys = Object.keys(data[0]);  // Get the keys (columns) of the first object
//             for (let i = 0; i < keys.length; i += chunkSize) {
//                 result.push(keys.slice(i, i + chunkSize));
//             }
//         }
//         return result;
//     };

//     // Get the chunks of columns (10 columns per page)
//     const columnsChunks = getChunks(admins, 10);

//     const [pageIndex, setPageIndex] = useState(0);

//     // Inline styles for table elements
//     const tableStyles = {
//         table: {
//             width: '100%',
//             borderCollapse: 'collapse',
//             marginBottom: '20px',
//         },
//         th: {
//             padding: '12px',
//             backgroundColor: '#f8f9fa',
//             textAlign: 'left',
//             cursor: 'pointer',
//             borderBottom: '2px solid #ddd',
//         },
//         td: {
//             padding: '12px',
//             border: '1px solid #ddd',
//         },
//         pagination: {
//             marginTop: '20px',
//             textAlign: 'center',
//         },
//         button: {
//             padding: '5px 10px',
//             margin: '0 5px',
//             cursor: 'pointer',
//             borderRadius: '5px',
//         },
//         disabledButton: {
//             backgroundColor: '#ccc',
//             cursor: 'not-allowed',
//         },
//     };

//     const currentPageData = columnsChunks[pageIndex] || [];



//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         const day = String(date.getDate()).padStart(2, '0');  // Ensure two-digit day
//         const month = String(date.getMonth() + 1).padStart(2, '0');  // Ensure two-digit month (months are 0-indexed)
//         const year = date.getFullYear();
        
//         return `${day}-${month}-${year}`;
//     };
//     const itemsPerPage = 5;
//     // const [pageIndex, setPageIndex] = useState(0);
  
//     // Calculate the total number of pages
//     const totalPages = Math.ceil(admins.length / itemsPerPage);
  
//     // Get the data for the current page
//     const currentData = admins.slice(
//       pageIndex * itemsPerPage,
//       (pageIndex + 1) * itemsPerPage
//     );
  
//     return (
//         <>
//             <div className="sidebar-nav">
//                 <h2>Admin Dashboard</h2>
//                 <ul>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>Profile</button></li>
//                     <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
//                 </ul>
//             </div>

//             <div className="profile-content ">
//                 <h1>Admins Details</h1>

//                 <Container className="mt-4">
//       <Row>
//         <Col>
        
//           {loading ? (
//             <div>Loading...</div>
//           ) : error ? (
//             <div>{error}</div>
//           ) : (
//             <div>
//               <Table striped bordered hover responsive>
//                 <thead>
//                   <tr>
//                     <th>ID</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>District</th>
//                     <th>Created At</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentData.map((admin, index) => (
//                     <tr key={index}>
//                       <td>{admin.id}</td>
//                       <td>{admin.name}</td>
//                       <td>{admin.email}</td>
//                       <td>{admin.district}</td>
//                       <td>{formatDate(admin.createdAtt)}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>

//               {/* Pagination */}
//               <Pagination>
//                 <Pagination.Prev
//                   onClick={() => setPageIndex(0)}
//                   disabled={pageIndex === 0}
//                 />
//                 <Pagination.Prev
//                   onClick={() => setPageIndex(pageIndex - 1)}
//                   disabled={pageIndex === 0}
//                 />
//                 {[...Array(totalPages)].map((_, index) => (
//                   <Pagination.Item
//                     key={index + 1}
//                     active={index === pageIndex}
//                     onClick={() => setPageIndex(index)}
//                   >
//                     {index + 1}
//                   </Pagination.Item>
//                 ))}
//                 <Pagination.Next
//                   onClick={() => setPageIndex(pageIndex + 1)}
//                   disabled={pageIndex === totalPages - 1}
//                 />
//                 <Pagination.Next
//                   onClick={() => setPageIndex(totalPages - 1)}
//                   disabled={pageIndex === totalPages - 1}
//                 />
//               </Pagination>
//               <span>
//                 Page <strong>{pageIndex + 1}</strong> of <strong>{totalPages}</strong>
//               </span>
//             </div>
//           )}
//         </Col>
//       </Row>
//     </Container>
//             </div>
//         </>
//     );
// };

// export default Admins;





import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";

const Admins = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const data = sessionStorage.getItem('userData');
        if (data) {
            setUserData(JSON.parse(data)); // Parse the stored JSON string
        }
    }, []);

    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('adminEmail');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };

    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the data from the Express API
    useEffect(() => {
        axios.get('https://lionsnewsapi.emedha.in/superAdmin/adminsignup')
            .then(response => {
                setAdmins(response.data);  // Update state with data
                setLoading(false);  // Stop loading
            })
            .catch(err => {
                setError('Error fetching data');
                setLoading(false);
            });
    }, []);

    // Pagination settings
    const itemsPerPage = 10;
    const [pageIndex, setPageIndex] = useState(0);

    // Calculate the total number of pages
    const totalPages = Math.ceil(admins.length / itemsPerPage);

    // Get the data for the current page
    const currentData = admins.slice(
        pageIndex * itemsPerPage,
        (pageIndex + 1) * itemsPerPage
    );

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');  // Ensure two-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Ensure two-digit month (months are 0-indexed)
        const year = date.getFullYear();
        
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <div className="sidebar-nav">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>Profile</button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="profile-content">
                <h1>Admins Details</h1>

                <Container className="mt-4">
                    <Row>
                        <Col>

                            {loading ? (
                                <div>Loading...</div>
                            ) : error ? (
                                <div>{error}</div>
                            ) : (
                                <div>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>District</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((admin, index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{admin.name}</td>
                                                    <td>{admin.email}</td>
                                                    <td>{admin.district}</td>
                                                    <td>{formatDate(admin.createdAtt)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    {/* Pagination */}
                                    <Pagination>
                                        <Pagination.Prev
                                            onClick={() => setPageIndex(pageIndex - 1)}
                                            disabled={pageIndex === 0}
                                        />
                                        {[...Array(totalPages)].map((_, index) => (
                                            <Pagination.Item
                                                key={index + 1}
                                                active={index === pageIndex}
                                                onClick={() => setPageIndex(index)}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        ))}
                                        <Pagination.Next
                                            onClick={() => setPageIndex(pageIndex + 1)}
                                            disabled={pageIndex === totalPages - 1}
                                        />
                                    </Pagination>
                                    <span>
                                        Page <strong>{pageIndex + 1}</strong> of <strong>{totalPages}</strong>
                                    </span>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Admins;
