// import React, { useState, useEffect } from 'react';
// import './Dashboard.css'; // Import the CSS file for styling
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
//   const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive

//   useEffect(() => {
//     // Fetch data from the backend API
//     fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         setPosts(data.filter(post => post.isArchived === 0)); // Filter out unarchived posts
//         setArchivedPosts(data.filter(post => post.isArchived === 1)); // Filter archived posts
//         setLoading(false); // Set loading to false once data is loaded
//       })
//       .catch((error) => {
//         console.error('Error fetching posts:', error);
//         setLoading(false); // Handle error case
//       });
//   }, []); // Empty dependency array ensures the effect runs once when the component mounts

//   // Function to handle post approval
//   const handleApprove = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         // Update post status to 'approved' in the state
//         setPosts(posts.map((post) =>
//           post.id === id ? { ...post, status: 'approved' } : post
//         ));
//       })
//       .catch((error) => {
//         console.error('Error approving post:', error);
//       });
//   };

//   // Function to handle post rejection
//   const handleReject = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/reject/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         // Update post status to 'rejected' in the state
//         setPosts(posts.map((post) =>
//           post.id === id ? { ...post, status: 'rejected' } : post
//         ));
//       })
//       .catch((error) => {
//         console.error('Error rejecting post:', error);
//       });
//   };

//   // Function to handle post deletion
//   const handleDelete = (id) => {
//     console.log('id for delete', id);
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         // Remove the deleted post from the state
//         setPosts(posts.filter((post) => post.id !== id));
//         setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting post:', error);
//       });
//   };

//   const handleLogout = () => {
//     // Clear sessionStorage
//     sessionStorage.removeItem('userId');
//     sessionStorage.removeItem('password');

//     // Redirect to login page
//     navigate('/');
//   };

//   // Function to handle switching between "Posts" and "Archive"
//   const handlePostsClick = () => {
//     setShowArchive(false); // Show active posts
//   };

//   const handleArchiveClick = () => {
//     setShowArchive(true); // Show archived posts
//   };

//   // Archive a post
//   const handleArchive = (id) => {
//     const postToArchive = posts.find((post) => post.id === id); // Find the post to archive
//     setPosts(posts.filter((post) => post.id !== id)); // Remove from active posts
//     setArchivedPosts([...archivedPosts, postToArchive]); // Add to archived posts

//     // Optionally, make a PUT request to your backend to update the archive status in the database.
//     fetch(`http://localhost:3000/api/otp/archive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error archiving post:', error);
//       });
//   };

//   const handleUnarchive = (id) => {
//     const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
//     setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
//     setPosts([...posts, postToUnarchive]); // Add it back to active posts

//     // Optionally, make a PUT request to your backend to update the unarchive status in the database.
//     fetch(`http://localhost:3000/api/otp/unarchive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error unarchiving post:', error);
//       });
//   };




//   return (
//     <div className="dashboard">
//       {/* Sidebar/Menu */}
//       <div className="sidebar">
//         <h3>Dashboard</h3>
//         <ul>
//           <li>
//             <button className="buttonA" onClick={handlePostsClick}>Posts</button>
//           </li>
//           <li>
//             <button className="buttonA" onClick={handleArchiveClick}>Archive</button>
//           </li>
//           <li>
//             <button className="buttonA" onClick={handleLogout}>LogOut</button>
//           </li>
//         </ul>
//       </div>

//       {/* Content Section */}
//       <div className="content">
//         {/* Posts Section */}
//         {!showArchive && (
//           <div className="card-container">
//             {posts.map((post) => (

//               <div className="card" key={post.id}>
//                 <img
//                   src={post.imageUri}
//                   alt={`Post ${post.id}`}
//                   className="card-image"
//                 />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   {/* <p>Date : {post.createdAt}</p> */}
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   {post.status === 'pending' && (
//                     <div className="actions">
//                       <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
//                       {/* <button className="buttonR" onClick={() => handleReject(post.id)}>Reject</button> */}

//                     </div>
//                   )}
//                   {/* {post.status === 'approved' && (
//                     <div className="actions">
//                       <button className="buttonA" onClick={() => handleArchive(post.id)}>Archive</button>
//                     </div>
//                   )} */}

//                   {/* Actions for active posts (approved or rejected) */}
//                   {!showArchive && (post.status === 'approved' || post.status === 'rejected') && (
//                     <div className="actions">
//                       <button className="buttonAr" onClick={() => handleArchive(post.id)}> Archive</button>
//                     </div>
//                   )}

//                   {/* Delete Button */}
//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}

//         {/* Archived Section */}
//         {showArchive && (
//           <div className="card-container">
//             {archivedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   {/* Actions for archived posts */}
//                   {/* <div className="actions">
//                     <button className="buttonA m-2" onClick={() => handleUnarchive(post.id)}>Unarchive</button>
//                     {post.status === 'pending' && (
//                       <>
//                         <button className="buttonA m-2" onClick={() => handleApprove(post.id)}>Accept</button>
//                         <button className="buttonR m-2" onClick={() => handleReject(post.id)}>Reject</button>
//                       </>
//                     )}
//                   </div> */}

//                   {/* Delete Button */}
//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

// import React, { useState, useEffect } from 'react';
// import './Dashboard.css'; // Import the CSS file for styling
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
//   const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive
//   const [searchCategory, setSearchCategory] = useState(""); // Search input for category
//   const [selectedDate, setSelectedDate] = useState(''); // Date filter
//   const [allDates, setAllDates] = useState([]); // Store unique dates

//   useEffect(() => {
//     // Fetch data from the backend API
//     fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         const unarchivedPosts = data.filter(post => post.isArchived === 0);
//         const archivedPosts = data.filter(post => post.isArchived === 1);

//         setPosts(unarchivedPosts);
//         setArchivedPosts(archivedPosts);
//         setLoading(false); // Set loading to false once data is loaded

//         // Extract all unique dates from the posts
//         const dates = [...new Set([...unarchivedPosts, ...archivedPosts].map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];
//         setAllDates(dates);
//       })
//       .catch((error) => {
//         console.error('Error fetching posts:', error);
//         setLoading(false); // Handle error case
//       });
//   }, []); // Empty dependency array ensures the effect runs once when the component mounts

//   // Filter posts by category
//   const handleSearch = (event) => {
//     setSearchCategory(event.target.value);
//   };

//   // Filter posts based on date
//   const handleDateFilterChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//   // Filter posts by category or email and date
//   const filterPostsByCategoryEmailAndDate = (posts) => {
//     return posts.filter((post) => {
//       const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
//       return (isCategoryMatch || isEmailMatch) && isDateMatch;
//     });
//   };
//   // Sort posts by date, today first
//   const sortedPosts = filterPostsByCategoryEmailAndDate(posts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const sortedArchivedPosts = filterPostsByCategoryEmailAndDate(archivedPosts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const handleLogout = () => {
//     sessionStorage.removeItem('userId');
//     sessionStorage.removeItem('userData');
//     sessionStorage.removeItem('authToken  ');
//     navigate('/');
//   };

//   const handlePostsClick = () => setShowArchive(false);
//   const handleArchiveClick = () => setShowArchive(true);

//   // Approve a post
//   const handleApprove = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.map((post) =>
//           post.id === id ? { ...post, status: 'approved' } : post
//         ));
//       })
//       .catch((error) => {
//         console.error('Error approving post:', error);
//       });
//   };

//   // Archive a post
//   const handleArchive = (id) => {
//     const postToArchive = posts.find((post) => post.id === id); // Find the post to archive
//     setPosts(posts.filter((post) => post.id !== id)); // Remove from active posts
//     setArchivedPosts([...archivedPosts, postToArchive]); // Add to archived posts

//     fetch(`http://localhost:3000/api/otp/archive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error archiving post:', error);
//       });
//   };

//   const handleUnarchive = (id) => {
//     const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
//     setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
//     setPosts([...posts, postToUnarchive]); // Add it back to active posts

//     fetch(`http://localhost:3000/api/otp/unarchive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error unarchiving post:', error);
//       });
//   };

//   const handleDelete = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.filter((post) => post.id !== id));
//         setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting post:', error);
//       });
//   };

//   return (
//     <div className="dashboard">
//       <div className="sidebar">
//         <h3>Dashboard</h3>
//         <ul>
//           <li><button className="buttonA" onClick={handlePostsClick}>Posts</button></li>
//           <li><button className="buttonA" onClick={handleArchiveClick}>Archive</button></li>
//           <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//         </ul>
//       </div>

//       <div className="content">
//         {/* Filters Section */}
//         <div className="filters">
//           <input
//             type="text"
//             placeholder="Search by category"
//             value={searchCategory}
//             onChange={handleSearch}
//           />
//           <select value={selectedDate} onChange={handleDateFilterChange}>
//             <option value="">All Dates</option>
//             {allDates.map((date, index) => (
//               <option key={index} value={date}>
//                 {date}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* Posts Section */}
//         {!showArchive && (
//           <div className="card-container">
//             {sortedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>district : {post.district || "null"}</p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   {post.status === 'pending' && (
//                     <div className="actions">
//                       <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
//                     </div>
//                   )}

//                   {post.status === 'approved' || post.status === 'rejected' ? (
//                     <div className="actions">
//                       <button className="buttonAr" onClick={() => handleArchive(post.id)}> Archive</button>
//                     </div>
//                   ) : null}

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}

//         {/* Archived Section */}
//         {showArchive && (
//           <div className="card-container">
//             {sortedArchivedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                     {/* <button className="buttonAr" onClick={() => handleUnarchive(post.id)}>Unarchive</button> */}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import './Dashboard.css'; // Import the CSS file for styling
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
//   const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive
//   const [searchCategory, setSearchCategory] = useState(""); // Search input for category
//   const [selectedDate, setSelectedDate] = useState(''); // Date filter
//   const [allDates, setAllDates] = useState([]); // Store unique dates

//   // Fetch the district from sessionStorage
//   const userDistrict = sessionStorage.getItem('userDistrict');

//   useEffect(() => {
//     // Fetch data from the backend API
//     fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         // Filter posts based on district
//         const filteredPosts = data.filter(post => post.district === userDistrict);

//         // Separate unarchived and archived posts after district filter
//         const unarchivedPosts = filteredPosts.filter(post => post.isArchived === 0);
//         const archivedPosts = filteredPosts.filter(post => post.isArchived === 1);

//         setPosts(unarchivedPosts);
//         setArchivedPosts(archivedPosts);
//         setLoading(false); // Set loading to false once data is loaded

//         // Extract all unique dates from the filtered posts
//         const dates = [...new Set(filteredPosts.map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];
//         setAllDates(dates);
//       })
//       .catch((error) => {
//         console.error('Error fetching posts:', error);
//         setLoading(false); // Handle error case
//       });
//   }, []); // Empty dependency array ensures the effect runs once when the component mounts

//   // Filter posts by category
//   const handleSearch = (event) => {
//     setSearchCategory(event.target.value);
//   };

//   // Filter posts based on date
//   const handleDateFilterChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//   // Filter posts by category or email and date
//   const filterPostsByCategoryEmailAndDate = (posts) => {
//     return posts.filter((post) => {
//       const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
//       return (isCategoryMatch || isEmailMatch) && isDateMatch;
//     });
//   };

//   // Sort posts by date, today first
//   const sortedPosts = filterPostsByCategoryEmailAndDate(posts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const sortedArchivedPosts = filterPostsByCategoryEmailAndDate(archivedPosts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const handleLogout = () => {
//     sessionStorage.removeItem('userId');
//     sessionStorage.removeItem('userData');
//     sessionStorage.removeItem('authToken');
//     sessionStorage.removeItem('userDistrict'); // Clear the district from sessionStorage
//     navigate('/');
//   };

//   const handlePostsClick = () => setShowArchive(false);
//   const handleArchiveClick = () => setShowArchive(true);

//   // Approve a post
//   const handleApprove = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.map((post) =>
//           post.id === id ? { ...post, status: 'approved' } : post
//         ));
//       })
//       .catch((error) => {
//         console.error('Error approving post:', error);
//       });
//   };

//   // Archive a post
//   const handleArchive = (id) => {
//     const postToArchive = posts.find((post) => post.id === id); // Find the post to archive
//     setPosts(posts.filter((post) => post.id !== id)); // Remove from active posts
//     setArchivedPosts([...archivedPosts, postToArchive]); // Add to archived posts

//     fetch(`https://lionsnewsapi.emedha.in/api/otp/archive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       // .then(() => {
//       //   setPosts(posts.map((post) =>
//       //     post.id === id ? { ...post, status: 'archive' } : post
//       //   ));
//       // })
//       .catch((error) => {
//         console.error('Error archiving post:', error);
//       });
//   };

//   const handleUnarchive = (id) => {
//     const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
//     setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
//     setPosts([...posts, postToUnarchive]); // Add it back to active posts

//     fetch(`https://lionsnewsapi.emedha.in/api/otp/unarchive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error unarchiving post:', error);
//       });
//   };

//   const handleDelete = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.filter((post) => post.id !== id));
//         setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting post:', error);
//       });
//   };

//   return (
//     <div className="dashboard">
//       <div className="sidebar">
//         <h3>Dashboard</h3>
//         <ul>
//           <li><button className="buttonA" onClick={handlePostsClick}>Posts</button></li>
//           <li><button className="buttonA" onClick={handleArchiveClick}>Archive</button></li>
//           <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//         </ul>
//       </div>

//       <div className="content">
//         {/* Filters Section */}
//         <div className="filters">
//           <input
//             type="text"
//             placeholder="Search by category"
//             value={searchCategory}
//             onChange={handleSearch}
//           />
//           <select value={selectedDate} onChange={handleDateFilterChange}>
//             <option value="">All Dates</option>
//             {allDates.map((date, index) => (
//               <option key={index} value={date}>
//                 {date}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* Posts Section */}
//         {!showArchive && (
//           <div className="card-container">
//             {sortedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>district : {post.district || "null"}</p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   {post.status === 'pending' && (
//                     <div className="actions">
//                       <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
//                     </div>
//                   )}

//                   {post.status === 'approved' || post.status === 'rejected' ? (
//                     <div className="actions">
//                       <button className="buttonAr" onClick={() => handleArchive(post.id)}> Archive</button>
//                     </div>
//                   ) : null}

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}

//         {/* Archived Section */}
//         {showArchive && (
//           <div className="card-container">
//             {sortedArchivedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                     {/* <button className="buttonAr" onClick={() => handleUnarchive(post.id)}>Unarchive</button> */}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import './Dashboard.css'; // Import the CSS file for styling
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
//   const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive
//   const [searchCategory, setSearchCategory] = useState(""); // Search input for category
//   const [selectedDate, setSelectedDate] = useState(''); // Date filter
//   const [allDates, setAllDates] = useState([]); // Store unique dates



//   const [showMonthPopup, setShowMonthPopup] = useState(false); // Show popup for month selection
//   const [selectedMonth, setSelectedMonth] = useState(''); // Store selected month
//   const [selectedPostId, setSelectedPostId] = useState(null); // Store the post ID to be archived
//   const [selectedPostApproveId, setSelectedPostApproveId] = useState(null); // Store post ID for approval

//   const [showMonthFilter, setShowMonthFilter] = useState(false); // To toggle month filter visibility


//   // Fetch the district from sessionStorage
//   const userDistrict = sessionStorage.getItem('userDistrict');

//   useEffect(() => {
//     // Fetch data from the backend API
//     fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         // Filter posts based on district
//         const filteredPosts = data.filter(post => post.district === userDistrict);

//         // Separate unarchived and archived posts after district filter
//         const unarchivedPosts = filteredPosts.filter(post => post.isArchived === 0);
//         const archivedPosts = filteredPosts.filter(post => post.isArchived === 1);

//         setPosts(unarchivedPosts);
//         setArchivedPosts(archivedPosts);
//         setLoading(false); // Set loading to false once data is loaded

//         // Extract all unique dates from the filtered posts
//         const dates = [...new Set(filteredPosts.map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];
//         setAllDates(dates);
//       })
//       .catch((error) => {
//         console.error('Error fetching posts:', error);
//         setLoading(false); // Handle error case
//       });
//   }, []); // Empty dependency array ensures the effect runs once when the component mounts

//   // Filter posts by category
//   const handleSearch = (event) => {
//     setSearchCategory(event.target.value);
//   };

//   // Filter posts based on date
//   const handleDateFilterChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//     // Filter posts by selected month
//     const filterPostsByMonth = (posts, selectedMonth) => {
//       if (!selectedMonth) return posts; // If no month is selected, return all posts
//       return posts.filter(post => {
//         const postMonth = new Date(post.createdAt).toLocaleString('default', { month: 'long' });
//         return postMonth === selectedMonth;
//       });
//     };

//   // Filter posts by category or email and date
//   const filterPostsByCategoryEmailAndDate = (posts) => {
//     return posts.filter((post) => {
//       const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
//       return (isCategoryMatch || isEmailMatch) && isDateMatch;
//     });
//   };

//   // Sort posts by date, today first
//   const sortedPosts = filterPostsByCategoryEmailAndDate(posts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const sortedArchivedPosts = filterPostsByCategoryEmailAndDate(archivedPosts).sort((a, b) => {
//     const aDate = new Date(a.createdAt);
//     const bDate = new Date(b.createdAt);
//     return bDate - aDate; // Sort descending by date
//   });

//   const handleLogout = () => {
//     sessionStorage.removeItem('userId');
//     sessionStorage.removeItem('userData');
//     sessionStorage.removeItem('authToken');
//     sessionStorage.removeItem('userDistrict'); // Clear the district from sessionStorage
//     navigate('/');
//   };

//   const handlePostsClick = () => setShowArchive(false);
//   const handleArchiveClick = () => setShowArchive(true);

//   // Approve a post
//   const handleApprove = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.map((post) =>
//           post.id === id ? { ...post, status: 'approved' } : post
//         ));
//       })
//       .catch((error) => {
//         console.error('Error approving post:', error);
//       });
//   };

//   const handleArchive = (id) => {
//     setShowMonthPopup(true); // Show the popup to select month
//     setSelectedPostId(id); // Store the selected post's ID
//   };

//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//   };

//   const handleSubmitMonth = () => {
//     if (!selectedMonth) {
//       alert('Please select a month');
//       return;
//     }

//     fetch(`http://localhost:3000/api/otp/archive/${selectedPostId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         month: selectedMonth, // Send the selected month
//       }),
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.filter(post => post.id !== selectedPostId)); // Remove from active posts
//         const archivedPost = posts.find(post => post.id === selectedPostId);
//         setArchivedPosts([...archivedPosts, { ...archivedPost, month: selectedMonth }]); // Add to archived posts
//         setShowMonthPopup(false); // Close the popup
//       })
//       .catch((error) => {
//         console.error('Error archiving post:', error);
//       });
//   };


//   const handleUnarchive = (id) => {
//     const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
//     setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
//     setPosts([...posts, postToUnarchive]); // Add it back to active posts

//     fetch(`https://lionsnewsapi.emedha.in/api/otp/unarchive/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .catch((error) => {
//         console.error('Error unarchiving post:', error);
//       });
//   };

//   const handleDelete = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.filter((post) => post.id !== id));
//         setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting post:', error);
//       });
//   };

//   return (
//     <div className="dashboard">
//       <div className="sidebar">
//         <h3>Dashboard</h3>
//         <ul>
//           <li><button className="buttonA" onClick={handlePostsClick}>Posts</button></li>
//           <li><button className="buttonA" onClick={handleArchiveClick}>Archive</button></li>
//           <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//         </ul>
//       </div>

//       <div className="content">
//         {/* Filters Section */}
//         <div className="filters">
//           <input
//             type="text"
//             placeholder="Search by category"
//             value={searchCategory}
//             onChange={handleSearch}
//           />
//           <select value={selectedDate} onChange={handleDateFilterChange}>
//             <option value="">All Dates</option>
//             {allDates.map((date, index) => (
//               <option key={index} value={date}>
//                 {date}
//               </option>
//             ))}
//           </select>

//           {showArchive && (
//             <select value={selectedMonth} onChange={handleMonthChange}>
//               <option value="">Select Month</option>
//               <option value="January">January</option>
//               <option value="February">February</option>
//               <option value="March">March</option>
//               <option value="April">April</option>
//               <option value="May">May</option>
//               <option value="June">June</option>
//               <option value="July">July</option>
//               <option value="August">August</option>
//               <option value="September">September</option>
//               <option value="October">October</option>
//               <option value="November">November</option>
//               <option value="December">December</option>
//             </select>


//           )}
//           {/* </div> */}
//         </div>

//         {/* Posts Section */}
//         {!showArchive && (
//           <div className="card-container">
//             {sortedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p>district : {post.district || "null"}</p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   {post.status === 'pending' && (
//                     <div className="actions">
//                       <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
//                     </div>
//                   )}

//                   {post.status === 'approved' || post.status === 'rejected' ? (
//                     <div className="actions">
//                       <button className="buttonAr" onClick={() => handleArchive(post.id)}>Archive</button>
//                     </div>
//                   ) : null}

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}

//         {/* Archived Section */}
//         {showArchive && (
//           <div className="card-container">

//             {sortedArchivedPosts.map((post) => (
//               <div className="card" key={post.id}>
//                 <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//                 <div className="card-body">
//                   <h2>Text: {post.textInput}</h2>
//                   <p>Category: {post.category || 'No category'}</p>
//                   <p>Email: {post.email}</p>
//                   <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                     Status: {post.status}
//                   </p>
//                   <p style={{ color: 'black' }}>Month : {post.month}</p>
//                   <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

//                   <div className="actions">
//                     <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                     {/* <button className="buttonAr" onClick={() => handleUnarchive(post.id)}>Unarchive</button> */}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}


//         {showMonthPopup && (
//           <div className="month-popup">
//             <div className="popup-content">
//               <h3>Which Month you have to Archive the Post</h3>
//               <select value={selectedMonth} onChange={handleMonthChange}>
//                 <option value="">Select Month</option>
//                 <option value="January">January</option>
//                 <option value="February">February</option>
//                 <option value="March">March</option>
//                 <option value="April">April</option>
//                 <option value="May">May</option>
//                 <option value="June">June</option>
//                 <option value="July">July</option>
//                 <option value="August">August</option>
//                 <option value="September">September</option>
//                 <option value="October">October</option>
//                 <option value="November">November</option>
//                 <option value="December">December</option>
//               </select>
//               <button onClick={handleSubmitMonth}>Submit</button>
//               <button onClick={() => setShowMonthPopup(false)}>Cancel</button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;




import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
  const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive
  const [searchCategory, setSearchCategory] = useState(""); // Search input for category
  const [selectedDate, setSelectedDate] = useState(''); // Date filter
  const [allDates, setAllDates] = useState([]); // Store unique dates

  const [showMonthPopup, setShowMonthPopup] = useState(false); // Show popup for month selection
  const [selectedMonth, setSelectedMonth] = useState(''); // Store selected month
  const [selectedPostId, setSelectedPostId] = useState(null); // Store the post ID to be archived
  const [selectedPostApproveId, setSelectedPostApproveId] = useState(null); // Store post ID for approval

  const [showMonthFilter, setShowMonthFilter] = useState(false); // To toggle month filter visibility

  // Fetch the district from sessionStorage
  const userDistrict = sessionStorage.getItem('userDistrict');

  useEffect(() => {
    // Fetch data from the backend API
    fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
      .then((response) => response.json())
      .then((data) => {
        // Filter posts based on district
        const filteredPosts = data.filter(post => post.district === userDistrict);

        // Separate unarchived and archived posts after district filter
        const unarchivedPosts = filteredPosts.filter(post => post.isArchived === 0);
        const archivedPosts = filteredPosts.filter(post => post.isArchived === 1);

        setPosts(unarchivedPosts);
        setArchivedPosts(archivedPosts);
        setLoading(false); // Set loading to false once data is loaded

        // Extract all unique dates from the filtered posts
        const dates = [...new Set(filteredPosts.map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];

        setAllDates(dates);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setLoading(false); // Handle error case
      });
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  // Filter posts by category
  const handleSearch = (event) => {
    setSearchCategory(event.target.value);
  };

  // Filter posts based on date
  const handleDateFilterChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleMonthFilterChange = (event) => {
    setSelectedMonth(event.target.value); // Update the selected month
  };



  // Filter posts by selected month
  // const filterPostsByMonth = (posts, selectedMonth) => {
  //   if (!selectedMonth) return posts; // If no month is selected, return all posts
  //   return posts.filter(post => {
  //     const postMonth = new Date(post.createdAt).toLocaleString('default', { month: 'long' });
  //     return postMonth === selectedMonth;
  //   });
  // };
  // const filterPostsByMonth = (posts, selectedMonth, selectedDate) => {
  //   if (!selectedMonth && !selectedDate) {
  //     return posts; // If no month or date is selected, return all posts
  //   }

  //   return posts.filter(post => {
  //     const postDate = new Date(post.createdAt); // Convert the createdAt field to a Date object
  //     const postMonth = postDate.toLocaleString('default', { month: 'long' }); // Get the month name (e.g., "January")
  //     const postDay = postDate.getDate(); // Get the day of the month (1, 2, 3, ..., 31)

  //     // Check if the month matches and if the date matches (if selected)
  //     const matchesMonth = selectedMonth ? postMonth === selectedMonth : true;
  //     const matchesDate = selectedDate ? postDay === parseInt(selectedDate, 10) : true; // compare selectedDate as number

  //     return matchesMonth && matchesDate; // Only include posts that match both the month and date
  //   });
  // };

  const filterPostsByMonth = (posts, selectedMonth, selectedDate) => {
    //   if (!selectedMonth = (posts, selectedMonth, selectedDate) => {
    return posts.filter(post => {
      const postMonth = post.month; // Get the month of the post
      const postDate = new Date(post.createdAt).toLocaleDateString('en-CA'); // Convert the date to a standard format

      // Filter by both month and date
      const matchesMonth = selectedMonth ? postMonth === selectedMonth : true;
      const matchesDate = selectedDate ? postDate === selectedDate : true;

      return matchesMonth && matchesDate;
    });
  };


  // Filter posts by category or email and date
  const filterPostsByCategoryEmailAndDate = (posts) => {
    return posts.filter((post) => {
      const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
      const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
      const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
      return (isCategoryMatch || isEmailMatch) && isDateMatch;
    });
  };

  // Sort posts by date, today first
  const sortedPosts = filterPostsByCategoryEmailAndDate(posts).sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return bDate - aDate; // Sort descending by date
  });

  // const sortedArchivedPosts = filterPostsByCategoryEmailAndDate(archivedPosts).sort((a, b) => {
  //   const aDate = new Date(a.createdAt);
  //   const bDate = new Date(b.createdAt);
  //   return bDate - aDate; // Sort descending by date
  // });
  const sortedArchivedPosts = filterPostsByMonth(archivedPosts, selectedMonth).sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return bDate - aDate;
  });


  const handleLogout = () => {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('userDistrict');
    navigate('/');
  };

  const handlePostsClick = () => setShowArchive(false);
  const handleArchiveClick = () => setShowArchive(true);

  // Approve a post
  const handleApprove = (id) => {
    fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
      method: 'PUT',
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.map((post) =>
          post.id === id ? { ...post, status: 'approved' } : post
        ));
      })
      .catch((error) => {
        console.error('Error approving post:', error);
      });
  };

  const handleArchive = (id) => {
    setShowMonthPopup(true); // Show the popup to select month
    setSelectedPostId(id); // Store the selected post's ID
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleSubmitMonth = () => {
    if (!selectedMonth) {
      alert('Please select a month');
      return;
    }

    fetch(`https://lionsnewsapi.emedha.in/api/otp/archive/${selectedPostId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        month: selectedMonth, // Send the selected month
      }),
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.filter(post => post.id !== selectedPostId)); // Remove from active posts
        const archivedPost = posts.find(post => post.id === selectedPostId);
        setArchivedPosts([...archivedPosts, { ...archivedPost, month: selectedMonth }]); // Add to archived posts
        setShowMonthPopup(false); // Close the popup
      })
      .catch((error) => {
        console.error('Error archiving post:', error);
      });
  };

  const handleUnarchive = (id) => {
    const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
    setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
    setPosts([...posts, postToUnarchive]); // Add it back to active posts

    fetch(`https://lionsnewsapi.emedha.in/api/otp/unarchive/${id}`, {
      method: 'PUT',
    })
      .then((response) => response.text())
      .catch((error) => {
        console.error('Error unarchiving post:', error);
      });
  };

  const handleDelete = (id) => {
    fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.filter((post) => post.id !== id));
        setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
      })
      .catch((error) => {
        console.error('Error deleting post:', error);
      });
  };
console.log(archivedPosts)
  return (
    <div className="dashboard">
      <div className="sidebar-nav">
        <h2>Dashboard</h2>
        <ul>
          <li><button className="side-button" onClick={handlePostsClick}>Posts</button></li>
          <li><button className="side-button" onClick={handleArchiveClick}>Archive</button></li>
          {/* <li><button className="buttonA" onClick={handleGreetingsClick}>Greetings</button></li> */}
         <li> <button className="side-button" onClick={() => window.location.href = '/greetings'}>
            Greetings
          </button></li>
        
         <li><button className="side-button" onClick={() => window.location.href = '/profile'}>
            Profile
          </button></li>
          <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
          
        </ul>
      </div>

      <div className="content-section">
        {/* Filters Section */}
        <div className="filters">
          <input
            type="text"
            placeholder="Search by category"
            value={searchCategory}
            onChange={handleSearch}
          />
          {/* <select value={selectedDate} onChange={handleDateFilterChange}>
            <option value="">All Dates</option>
            {allDates.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select> */}
          <select value={selectedDate} onChange={handleDateFilterChange}>
            <option value="">All Dates</option>
            {allDates.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>


          {showArchive && (
            <select value={selectedMonth} onChange={handleMonthFilterChange}>
              <option value="">Select Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          )}

        </div>

        {/* Posts Section */}
        {!showArchive && (
          <div className="card-container">
            {sortedPosts.map((post) => (
              <div className="card" key={post.id}>
                <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
                <div className="card-body">
                  <h2>Text: {post.textInput}</h2>
                  <p>Category: {post.category || 'No category'}</p>
                  <p>Email: {post.email}</p>
                  <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
                    Status: {post.status}
                  </p>
                  <p>District : {post.district || "null"}</p>
                  <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

                  {post.status === 'pending' && (
                    <div className="actions">
                      <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
                    </div>
                  )}

                  {post.status === 'approved' || post.status === 'rejected' ? (
                    <div className="actions">
                      <button className="buttonAr" onClick={() => handleArchive(post.id)}>Archive</button>
                    </div>
                  ) : null}

                  <div className="actions">
                    <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Archived Section */}
        {showArchive && (
          <div className="card-container">
            {filterPostsByMonth(sortedArchivedPosts, selectedMonth, selectedDate).map((post) => (
              <div className="card" key={post.id}>
                <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
                <div className="card-body">
                  <h2>Text: {post.textInput}</h2>
                  <p>Category: {post.category || 'No category'}</p>
                  <p>Email: {post.email}</p>
                  <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
                    Status: {post.status}
                  </p>
                  <p>District : {post.district || "null"}</p>
                  <p style={{ color: 'black' }}>Month : {post.month}</p>
                  <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

                  <div className="actions">
                    <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}


        {/* Month Popup */}
        {showMonthPopup && (
          <div className="month-popup">
            <div className="popup-content">
              <h3>Which Month you have to Archive the Post</h3>
              <select value={selectedMonth} onChange={handleMonthChange}>
                <option value="">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
              <button onClick={handleSubmitMonth}>Submit</button>
              <button onClick={() => setShowMonthPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;

// import React, { useState, useEffect } from 'react';
// import './Dashboard.css';
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [archivedPosts, setArchivedPosts] = useState([]);
//   const [showArchive, setShowArchive] = useState(false);
//   const [searchCategory, setSearchCategory] = useState('');
//   const [selectedDate, setSelectedDate] = useState('');
//   const [selectedMonth, setSelectedMonth] = useState('');
//   const [allDates, setAllDates] = useState([]);
//   const [filteredDates, setFilteredDates] = useState([]); // Store the filtered dates based on selected month

//   const [showMonthPopup, setShowMonthPopup] = useState(false);
//   const [selectedPostId, setSelectedPostId] = useState(null);
//   const userDistrict = sessionStorage.getItem('userDistrict');

//   useEffect(() => {
//     fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         const filteredPosts = data.filter(post => post.district === userDistrict);
//         const unarchivedPosts = filteredPosts.filter(post => post.isArchived === 0);
//         const archivedPosts = filteredPosts.filter(post => post.isArchived === 1);

//         setPosts(unarchivedPosts);
//         setArchivedPosts(archivedPosts);
//         setLoading(false);

//         // Extract all unique dates and months from the posts
//         const dates = [...new Set(filteredPosts.map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];
//         setAllDates(dates);

//         // Store the filtered dates for month selection
//         const months = [...new Set(filteredPosts.map(post => new Date(post.createdAt).getMonth() + 1))]; // Months from 1 to 12
//         setFilteredDates(months);
//       })
//       .catch((error) => {
//         console.error('Error fetching posts:', error);
//         setLoading(false);
//       });
//   }, []);

//   const handleSearch = (event) => setSearchCategory(event.target.value);
//   const handleDateFilterChange = (event) => setSelectedDate(event.target.value);

//   // Filter posts by category or email and date
//   const filterPostsByCategoryEmailAndDate = (posts) => {
//     return posts.filter((post) => {
//       const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
//       const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
//       const isMonthMatch = selectedMonth ? new Date(post.createdAt).getMonth() + 1 === parseInt(selectedMonth) : true;
//       return (isCategoryMatch || isEmailMatch) && isDateMatch && isMonthMatch;
//     });
//   };

//   // Filter posts based on month and date
//   const filteredPosts = filterPostsByCategoryEmailAndDate(posts);
//   const filteredArchivedPosts = filterPostsByCategoryEmailAndDate(archivedPosts);

//   // Handle month change to filter posts by month
//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//     setSelectedDate(''); // Reset the date when month changes
//   };

//   // Handle date change
//   const handleDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//   const handleLogout = () => {
//     sessionStorage.removeItem('userId');
//     sessionStorage.removeItem('userData');
//     sessionStorage.removeItem('authToken');
//     sessionStorage.removeItem('userDistrict');
//     navigate('/');
//   };

//   const handlePostsClick = () => setShowArchive(false);
//   const handleArchiveClick = () => setShowArchive(true);

//   const handleApprove = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
//       method: 'PUT',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.map((post) => post.id === id ? { ...post, status: 'approved' } : post));
//       })
//       .catch((error) => {
//         console.error('Error approving post:', error);
//       });
//   };

//   const handleDelete = (id) => {
//     fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.text())
//       .then(() => {
//         setPosts(posts.filter((post) => post.id !== id));
//         setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting post:', error);
//       });
//   };

//   return (
//     <div className="dashboard">
//       <div className="sidebar">
//         <h3>Dashboard</h3>
//         <ul>
//           <li><button className="buttonA" onClick={handlePostsClick}>Posts</button></li>
//           <li><button className="buttonA" onClick={handleArchiveClick}>Archive</button></li>
//           <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//         </ul>
//       </div>

//       <div className="content">
//         {/* Filters Section */}
//         <div className="filters">
//           {/* Month Dropdown */}
//           <select value={selectedMonth} onChange={handleMonthChange}>
//             <option value="">Select Month</option>
//             {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((month) => (
//               <option key={month} value={month}>
//                 {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
//               </option>
//             ))}
//           </select>

//           {/* Date Dropdown - Show only when a month is selected */}
//           {selectedMonth && (
//             <select value={selectedDate} onChange={handleDateChange}>
//               <option value="">Select Date</option>
//               {filteredPosts
//                 .filter(post => new Date(post.createdAt).getMonth() + 1 === parseInt(selectedMonth))
//                 .map((post, index) => (
//                   <option key={index} value={new Date(post.createdAt).toLocaleDateString('en-CA')}>
//                     {new Date(post.createdAt).toLocaleDateString('en-CA')}
//                   </option>
//                 ))}
//             </select>
//           )}
//         </div>

//         {/* Posts Section */}
//         <div className="card-container">
//           {(showArchive ? filteredArchivedPosts : filteredPosts).map((post) => (
//             <div className="card" key={post.id}>
//               <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
//               <div className="card-body">
//                 <h2>Text: {post.textInput}</h2>
//                 <p>Category: {post.category || 'No category'}</p>
//                 <p>Email: {post.email}</p>
//                 <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
//                   Status: {post.status}
//                 </p>
//                 <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>
//                 <div className="actions">
//                   {post.status === 'pending' && <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>}
//                   <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
