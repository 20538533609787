// import React, { useState, useEffect } from 'react';

// const Greetings = () => {
//   const [category, setCategory] = useState('');
//   const [image, setImage] = useState(null);
//   const [message, setMessage] = useState('');
//   const [images, setImages] = useState([]);

//   const handleCategoryChange = (e) => {
//     setCategory(e.target.value);
//   };

//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (!category || !image) {
//       setMessage('Please select a category and an image');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('image', image);
//     formData.append('category', category);

//     try {
//       const response = await fetch('http://localhost:5000/upload', {
//         method: 'POST',
//         body: formData,
//       });
//       const data = await response.json();
//       setMessage(data.message);
//     } catch (err) {
//       setMessage('Error uploading image');
//       console.error(err);
//     }
//   };

//   // Fetch images by category
//   const fetchImages = async (category) => {
//     const response = await fetch(`http://localhost:5000/images/${category}`);
//     const data = await response.json();
//     setImages(data);
//   };

//   useEffect(() => {
//     if (category) {
//       fetchImages(category);
//     }
//   }, [category]);

//   return (
//     <div>
//       <h1>Greetings</h1>

//       {/* Category and Image Upload */}
//       <div>
//         <input
//           type="text"
//           placeholder="Category"
//           value={category}
//           onChange={handleCategoryChange}
//         />
//         <input type="file" onChange={handleImageChange} />
//         <button onClick={handleUpload}>Upload</button>
//         <p>{message}</p>
//       </div>

//       {/* Display Images */}
//       <div>
//         <h2>Images in Category: {category}</h2>
//         <div>
//           {images.map((img) => (
//             <div key={img.id}>
//               <img src={`http://localhost:5000${img.image_url}`} alt={img.category} width="200" />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Greetings;


// import React, { useState, useEffect } from 'react';
// import { Container, Box, TextField, Button, Grid, Typography, Card, CardMedia, CardContent, Snackbar, Alert } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { useNavigate } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';



// const Greetings = () => {
//     const navigate = useNavigate();
//     const [category, setCategory] = useState('');
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);

//     // const handleCategoryChange = (e) => {
//     //     setCategory(e.target.value);
//     // };

//     const handleImageChange = (e) => {
//         setImage(e.target.files[0]);
//     };
//     const userDistrict = sessionStorage.getItem('userDistrict');
//     console.log(userDistrict)
//     const userId = sessionStorage.getItem('userId');
//     console.log(userId)

//     const handleUpload = async () => {
//         if (!category || !image) {
//             setMessage('Please select a category and an image.');
//             setOpenSnackbar(true);
//             return;
//         }

//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', category);

//         try {
//             const response = await fetch('http://localhost:3000/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchGreetings();
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setLoading(false);
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//             console.error(err);
//         }
//     };

//     const fetchImages = async (category) => {
//         const response = await fetch(`http://localhost:3000/admin/images/${category}`);
//         const data = await response.json();
//         setImages(data?.data ?? []); // Ensure data is always an array
//     };


//     useEffect(() => {
//         if (category) {
//             fetchImages(category);
//         }
//     }, [category]);

//     const handleCategoryChange = (e) => {
//         console.log(e.target.value); // Check the value as you type
//         setCategory(e.target.value);
//     };


//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };
//     console.log(images)

//     const [greetings, setGreetings] = useState([]);
//     const [error, setError] = useState(null);


//     const fetchGreetings = async () => {
//         try {
//             const response = await fetch('http://localhost:3000/admin/get/greetings');
//             if (!response.ok) {
//                 throw new Error('Failed to fetch greetings data');
//             }
//             const data = await response.json();
//             setGreetings(data);
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };



//     useEffect(() => {

//         fetchGreetings();
//     }, []);


//     console.log(greetings)

//     return (
//         <div className='dashboard'>
//         <Row>
//             <Col>
//             <div className="sidebar">
//                 <h3>Dashboard</h3>
//                 <ul>

//                     <li> <button className="buttonA" onClick={() => window.location.href = '/Dashboard'}>
//                         Posts
//                     </button></li>

//                     <li> <button className="buttonA" onClick={() => window.location.href = '/greetings'}>
//                         Greetings
//                     </button></li>

//                     <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//                 </ul>
//             </div>
//             </Col>
//             <Col>
//             <div className="content" maxWidth="md" sx={{ mt: 4 }}>
//                 <Typography variant="h4" gutterBottom align="center" color="primary">
//                     Greeting Image upload
//                 </Typography>
//                 <Box
//                     component="form"
//                     sx={{
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         gap: 2,
//                         mb: 4,
//                     }}
//                 >
//                     <TextField
//                         label="Category"
//                         variant="outlined"
//                         fullWidth
//                         value={category}
//                         onChange={handleCategoryChange}
//                         sx={{ maxWidth: 500 }}
//                     />
//                     <Button variant="contained" component="label" fullWidth sx={{ maxWidth: 500 }}>
//                         Upload Image
//                         <input type="file" hidden onChange={handleImageChange} />
//                     </Button>
//                     <LoadingButton
//                         variant="contained"
//                         onClick={handleUpload}
//                         loading={loading}
//                         fullWidth
//                         sx={{ maxWidth: 500 }}
//                     >
//                         Upload
//                     </LoadingButton>
//                 </Box>

//                 <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
//                     <Alert onClose={() => setOpenSnackbar(false)} severity={message === 'Error uploading image' ? 'error' : 'success'}>
//                         {message}
//                     </Alert>
//                 </Snackbar>

//                 <Grid container spacing={2}>
//                 {greetings.length > 0 ? (
//                     greetings.map((greeting) => (
//                         <Grid item xs={12} sm={6} md={4} key={greeting.id}>
//                             <Card>
//                                 <CardMedia
//                                     component="img"
//                                     alt={greeting.category}
//                                     height="200"
//                                     image={`http://localhost:5000${greeting.image_url}`}
//                                     title={greeting.category}
//                                 />
//                                 <CardContent>
//                                     <Typography variant="h6" color="textPrimary">
//                                         {greeting.category}
//                                     </Typography>
//                                     <Typography variant="body2" color="textSecondary">
//                                         {greeting.message}
//                                     </Typography>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))
//                 ) : (
//                     <Typography variant="body1" color="textSecondary" align="center">
//                         No greetings available.
//                     </Typography>
//                 )}
//             </Grid>
//             </div>
//             </Col>
//             </Row>
//         </div>
//     );
// };

// export default Greetings;



// import React, { useState, useEffect } from 'react';
// import { Container, Box, TextField, Button, Grid, Typography, Card, CardMedia, CardContent, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { useNavigate } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';

// const Greetings = () => {
//     const navigate = useNavigate();
//     const [category, setCategory] = useState('');
//     const [categorys,setCategorys] = useState('');
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [categories, setCategories] = useState([]); // New state for categories

//     const userDistrict = sessionStorage.getItem('userDistrict');
//     const userId = sessionStorage.getItem('userId');

//     const handleImageChange = (e) => {
//         setImage(e.target.files[0]);
//     };

//     const handleUpload = async () => {
//         if (!category || !image) {
//             setMessage('Please select a category and an image.');
//             setOpenSnackbar(true);
//             return;
//         }

//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', category);

//         try {
//             const response = await fetch('http://localhost:3000/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchImages(category); // Fetch images after upload
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setLoading(false);
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//         }
//     };

//     const fetchCategories = async () => {
//         try {
//             const response = await fetch('http://localhost:3000/admin/get/greetings'); // Endpoint to fetch categories
//             const data = await response.json();
//             // Extract unique categories from the data
//             const uniqueCategories = [...new Set(data.map((item) => item.category))];
//             setCategories(uniqueCategories); // Set categories
//             setCategorys(uniqueCategories)
//         } catch (err) {
//             console.error('Error fetching categories:', err);
//         }
//     };

//     const fetchImages = async (category) => {
//         try {
//             const response = await fetch(`http://localhost:3000/admin/images/${category}`);
//             const data = await response.json();
//             setImages(data?.data ?? []); // Ensure data is always an array
//         } catch (err) {
//             console.error('Error fetching images:', err);
//         }
//     };

//     useEffect(() => {
//         fetchCategories(); // Fetch categories when component mounts
//     }, []);

//     useEffect(() => {
//         if (category) {
//             fetchImages(category); // Fetch images for the selected category
//         }
//     }, [category]);

//     const handleCategoryChange = (e) => {
//         setCategory(e.target.value);
//     };

//     const handleCategoryChangee = (e) => {
//         setCategorys(e.target.value);
//     };

//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     const [greetings, setGreetings] = useState([]);
//     const [error, setError] = useState(null);

//     const fetchGreetings = async () => {
//         try {
//             const response = await fetch('http://localhost:3000/admin/get/greetings');
//             if (!response.ok) {
//                 throw new Error('Failed to fetch greetings data');
//             }
//             const data = await response.json();
//             setGreetings(data);
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchGreetings();
//     }, []);

//     return (
//         <div className="dashboard">
//             <Row>
//                 <Col>
//                     <div className="sidebar">
//                         <h3>Dashboard</h3>
//                         <ul>
//                             <li> 
//                                 <button className="buttonA" onClick={() => window.location.href = '/Dashboard'}>
//                                     Posts
//                                 </button>
//                             </li>

//                             <li> 
//                                 <button className="buttonA" onClick={() => window.location.href = '/greetings'}>
//                                     Greetings
//                                 </button>
//                             </li>

//                             <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//                         </ul>
//                     </div>
//                 </Col>
//                 <Col>
//                     <div className="content" maxWidth="md" sx={{ mt: 4 }}>
//                         <Typography variant="h4" gutterBottom align="center" color="primary">
//                             Greeting Image upload
//                         </Typography>
//                         <Box
//                             component="form"
//                             sx={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 alignItems: 'center',
//                                 gap: 2,
//                                 mb: 4,
//                             }}
//                         >
//                             {/* Image Upload Form */}
//                             <TextField
//                                 label="Category"
//                                 variant="outlined"
//                                 fullWidth
//                                 value={category}
//                                 onChange={handleCategoryChange}
//                                 sx={{ maxWidth: 500 }}
//                             />

//                             <Button variant="contained" component="label" fullWidth sx={{ maxWidth: 500 }}>
//                                 Upload Image
//                                 <input type="file" hidden onChange={handleImageChange} />
//                             </Button>

//                             <LoadingButton
//                                 variant="contained"
//                                 onClick={handleUpload}
//                                 loading={loading}
//                                 fullWidth
//                                 sx={{ maxWidth: 500 }}
//                             >
//                                 Upload
//                             </LoadingButton>
//                         </Box>

//                         {/* Snackbar for messages */}
//                         <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
//                             <Alert onClose={() => setOpenSnackbar(false)} severity={message === 'Error uploading image' ? 'error' : 'success'}>
//                                 {message}
//                             </Alert>
//                         </Snackbar>

//                         {/* Category Dropdown */}
//                         <FormControl fullWidth sx={{ maxWidth: 500, mb: 4 }}>
//                             <InputLabel>Category</InputLabel>
//                             <Select
//                                 label="Category"
//                                 value={category}
//                                 onChange={handleCategoryChangee}
//                             >
//                                 {categorys.map((cat, index) => (
//                                     <MenuItem key={index} value={cat}>
//                                         {cat}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>

//                         {/* Display Images */}
//                         <Grid container spacing={2}>
//                             {images.length > 0 ? (
//                                 images.map((image) => (
//                                     <Grid item xs={12} sm={6} md={4} key={image.id}>
//                                         <Card>
//                                             <CardMedia
//                                                 component="img"
//                                                 alt={image.category}
//                                                 height="200"
//                                                 image={`http://localhost:5000${image.image_url}`}
//                                                 title={image.category}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="h6" color="textPrimary">
//                                                     {image.category}
//                                                 </Typography>
//                                                 <Typography variant="body2" color="textSecondary">
//                                                     {image.message}
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </Grid>
//                                 ))
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary" align="center">
//                                     No images available for the selected category.
//                                 </Typography>
//                             )}
//                         </Grid>
//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Greetings;



// import React, { useState, useEffect } from 'react';
// import { Container, Box, TextField, Button, Grid, Typography, Card, CardMedia, CardContent, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { useNavigate } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';

// const Greetings = () => {
//     const navigate = useNavigate();
//     const [category, setCategory] = useState('');
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [categories, setCategories] = useState([]); // Categories for selection
//     const [greetings, setGreetings] = useState([]); // Greetings to display based on selected category
//     const userId = sessionStorage.getItem('userId');

//     // Fetch available categories
//     const fetchCategories = async () => {
//         try {
//             const response = await fetch('http://localhost:3000/admin/get/greetings');
//             const data = await response.json();
//             const uniqueCategories = [...new Set(data.map(item => item.category))];
//             setCategories(uniqueCategories);
//         } catch (err) {
//             console.error('Error fetching categories:', err);
//         }
//     };

//     // Fetch images based on category
//     const fetchImages = async (category) => {
//         try {
//             const response = await fetch(`http://localhost:3000/admin/images/${category}`);
//             const data = await response.json();
//             setImages(data?.data ?? []);
//         } catch (err) {
//             console.error('Error fetching images:', err);
//         }
//     };

//     // Fetch greetings for a selected category
//     const fetchGreetings = async (category) => {
//         try {
//             const response = await fetch(`http://localhost:3000/admin/images/${category}`);
//             const data = await response.json();
//             setGreetings(data);
//         } catch (err) {
//             console.error('Error fetching greetings:', err);
//         }
//     };

//     // Handle the upload of a greeting
//     const handleImageChange = (e) => {
//         setImage(e.target.files[0]);
//     };

//     const handleUpload = async () => {
//         if (!category || !image) {
//             setMessage('Please select a category and an image.');
//             setOpenSnackbar(true);
//             return;
//         }

//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', category);

//         try {
//             const response = await fetch('http://localhost:3000/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchImages(category); // Fetch images after upload
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setLoading(false);
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//         }
//     };

//     // Handle category change for posting images
//     const handleCategoryChange = (e) => {
//         setCategory(e.target.value);
//     };

//     // Handle category change for displaying greetings
//     const handleCategorySelect = (e) => {
//         const selectedCategory = e.target.value;
//         fetchGreetings(selectedCategory); // Fetch greetings based on selected category
//     };

//     // Logout handler
//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     useEffect(() => {
//         fetchCategories(); // Fetch categories when component mounts
//     }, []);

//     useEffect(() => {
//         if (category) {
//             fetchImages(category); // Fetch images when category is changed
//         }
//     }, [category]);

//     return (
//         <div className="dashboard">
//             <Row>
//                 <Col>
//                     <div className="sidebar">
//                         <h3>Dashboard</h3>
//                         <ul>
//                             <li> 
//                                 <button className="buttonA" onClick={() => window.location.href = '/Dashboard'}>
//                                     Posts
//                                 </button>
//                             </li>
//                             <li> 
//                                 <button className="buttonA" onClick={() => window.location.href = '/greetings'}>
//                                     Greetings
//                                 </button>
//                             </li>
//                             <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//                         </ul>
//                     </div>
//                 </Col>
//                 <Col>
//                     <div className="content" maxWidth="md" sx={{ mt: 4 }}>
//                         {/* Greeting Image Upload Section */}
//                         <Typography variant="h4" gutterBottom align="center" color="primary">
//                             Greeting Image Upload
//                         </Typography>
//                         <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
//                             <TextField
//                                 label="Category"
//                                 variant="outlined"
//                                 fullWidth
//                                 value={category}
//                                 onChange={handleCategoryChange}
//                                 sx={{ maxWidth: 500 }}
//                             />
//                             <Button variant="contained" component="label" fullWidth sx={{ maxWidth: 500 }}>
//                                 Upload Image
//                                 <input type="file" hidden onChange={handleImageChange} />
//                             </Button>
//                             <LoadingButton
//                                 variant="contained"
//                                 onClick={handleUpload}
//                                 loading={loading}
//                                 fullWidth
//                                 sx={{ maxWidth: 500 }}
//                             >
//                                 Upload
//                             </LoadingButton>
//                         </Box>

//                         {/* Snackbar for messages */}
//                         <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
//                             <Alert onClose={() => setOpenSnackbar(false)} severity={message === 'Error uploading image' ? 'error' : 'success'}>
//                                 {message}
//                             </Alert>
//                         </Snackbar>

//                         {/* Category Dropdown to display greetings */}
//                         <FormControl fullWidth sx={{ maxWidth: 500, mb: 4 }}>
//                             <InputLabel>Category</InputLabel>
//                             <Select
//                                 label="Category"
//                                 value={category}
//                                 onChange={handleCategorySelect}
//                             >
//                                 {categories.map((cat, index) => (
//                                     <MenuItem key={index} value={cat}>
//                                         {cat}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>

//                         {/* Display Greetings */}
//                         <Grid container spacing={2}>
//                             {greetings.length > 0 ? (
//                                 greetings.map((greeting, index) => (
//                                     <Grid item xs={12} sm={6} md={4} key={index}>
//                                         <Card>
//                                             <CardMedia
//                                                 component="img"
//                                                 alt={greeting.category}
//                                                 height="200"
//                                                 image={`http://localhost:3000${greeting.image_url}`}
//                                                 title={greeting.category}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="h6" color="textPrimary">
//                                                     {greeting.category}
//                                                 </Typography>
//                                                 <Typography variant="body2" color="textSecondary">
//                                                     {greeting.message}
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </Grid>
//                                 ))
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary" align="center">
//                                     No greetings available for the selected category.
//                                 </Typography>
//                             )}
//                         </Grid>
//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Greetings;


// import React, { useState, useEffect } from 'react';
// import { Container, Box, TextField, Button, Grid, Typography, Card, CardMedia, CardContent, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { useNavigate } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';
// import { FaCamera } from "react-icons/fa";




// const Greetings = () => {
//     const navigate = useNavigate();
//     const [uploadCategory, setUploadCategory] = useState(''); // Category for uploading
//     const [selectedCategory, setSelectedCategory] = useState(''); // Category for selecting greetings
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [categories, setCategories] = useState([]); // Categories for selection
//     const [categoriess, setCategoriess] = useState([]);
//     const [greetings, setGreetings] = useState([]); // Greetings to display based on selected category
//     const userId = sessionStorage.getItem('userId');

//     // Fetch available categories
//     // const fetchCategories = async () => {
//     //     try {
//     //         const response = await fetch('http://localhost:3000/admin/get/greetings');
//     //         const data = await response.json();
//     //         setCategoriess(data);
//     //         const uniqueCategories = [...new Set(data.map(item => item.category))];
//     //         setCategories(uniqueCategories);
//     //     } catch (err) {
//     //         console.error('Error fetching categories:', err);
//     //     }
//     // };

//     const fetchCategories = async () => {
//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/get/greetings');
//             const data = await response.json();

//             // Filter categories based on userId
//             const userCategories = data.filter(item => item.userId === userId); // Filter based on logged-in user

//             // Remove duplicate categories manually
//             const uniqueCategories = userCategories
//                 .map(item => item.category)
//                 .filter((value, index, self) => self.indexOf(value) === index); // Only add unique categories

//             setCategories(uniqueCategories); // Set the filtered unique categories
//             setCategoriess(userCategories);  // Optional: Store all categories if needed later
//         } catch (err) {
//             console.error('Error fetching categories:', err);
//         }
//     };



//     // Fetch images based on category
//     const fetchImages = async (category) => {
//         try {
//             const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
//             const data = await response.json();
//             setImages(data?.data ?? []);
//         } catch (err) {
//             console.error('Error fetching images:', err);
//         }
//     };

//     // Fetch greetings for a selected category
//     const fetchGreetings = async (category) => {
//         try {
//             const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
//             const data = await response.json();
//             setGreetings(data);
//         } catch (err) {
//             console.error('Error fetching greetings:', err);
//         }
//     };


//     const [previewUrl, setPreviewUrl] = useState(null);
//     // Handle the upload of a greeting
//     // const handleImageChange = (e) => {
//     //     setImage(e.target.files[0]);
//     // };
//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             setImage(file); // Set the selected image
//             setPreviewUrl(URL.createObjectURL(file)); // Create a URL for previewing the image
//         }
//     };

//     const handleUpload = async () => {
//         if (!uploadCategory || !image) {
//             setMessage('Please select a category and an image.');
//             setOpenSnackbar(true);
//             return;
//         }

//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', uploadCategory);

//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchImages(); // Fetch images after upload
//             fetchGreetings();
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setImage("");
//             setPreviewUrl("");
//             handleCategoryChange("");
//             setLoading(false);
//             window.location.reload();
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//         }
//     };

//     // Handle category change for posting images (uploadCategory)
//     const handleCategoryChange = (e) => {
//         setUploadCategory(e.target.value); // Only change the upload category
//     };

//     // Handle category change for displaying greetings (selectedCategory)
//     const handleCategorySelect = (e) => {
//         setSelectedCategory(e.target.value); // Only change the selected category for displaying greetings
//         const selectedCategory = e.target.value;
//         fetchGreetings(selectedCategory); // Fetch greetings based on selected category
//     };

//     // Logout handler
//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     useEffect(() => {
//         fetchCategories(); // Fetch categories when component mounts
//     }, []);

//     useEffect(() => {
//         if (selectedCategory) {
//             fetchGreetings(selectedCategory); // Fetch greetings when selected category changes
//         }
//     }, [selectedCategory]);

//     return (
//         <div className="dashboard">
//             <Row>
//                 <Col>
//                     <div className="sidebar">
//                         <h3>Dashboard</h3>
//                         <ul>
//                             <li>
//                                 <button className="buttonA" onClick={() => window.location.href = '/Dashboard'}>
//                                     Posts
//                                 </button>
//                             </li>
//                             <li>
//                                 <button className="buttonA" onClick={() => window.location.href = '/greetings'}>
//                                     Greetings
//                                 </button>
//                             </li>
//                             <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//                         </ul>
//                     </div>
//                 </Col>
//                 <Col>
//                     <div className="content" maxWidth="md" sx={{ mt: 4 }}>
//                         {/* Greeting Image Upload Section */}
//                         <Typography variant="h4" gutterBottom align="center" color="primary">
//                             Greeting Image Upload
//                         </Typography>
//                         <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
//                             <TextField
//                                 label="Category"
//                                 variant="outlined"
//                                 fullWidth
//                                 value={uploadCategory}
//                                 onChange={handleCategoryChange} // This updates the uploadCategory only
//                                 sx={{ maxWidth: 500 }}
//                             />
//                             <Button variant="contained" component="label" fullWidth sx={{ maxWidth: 200 }}>
//                                 <FaCamera /> Upload Image
//                                 <input type="file" hidden onChange={handleImageChange} />
//                             </Button>
//                             {previewUrl && (
//                                 <Box sx={{ mt: 2, textAlign: 'center' }}>
//                                     <Typography variant="body1" color="textSecondary">
//                                         Image Preview:
//                                     </Typography>
//                                     <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }} />
//                                 </Box>
//                             )}
//                             <LoadingButton
//                                 variant="contained"
//                                 onClick={handleUpload}
//                                 loading={loading}
//                                 fullWidth
//                                 sx={{ maxWidth: 500 }}
//                             >
//                                 Upload
//                             </LoadingButton>
//                         </Box>

//                         {/* Snackbar for messages */}
//                         <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
//                             <Alert onClose={() => setOpenSnackbar(false)} severity={message === 'Error uploading image' ? 'error' : 'success'}>
//                                 {message}
//                             </Alert>
//                         </Snackbar>

//                         {/* Category Dropdown to display greetings */}
//                         {/* <FormControl fullWidth sx={{ maxWidth: 500, mb: 4 }}>
//                             <InputLabel>Category</InputLabel>

//                             <Select
//                                 label="Category"
//                                 value={selectedCategory}  // This is bound to the selectedCategory state
//                                 onChange={handleCategorySelect}  // Updates the selectedCategory state on selection
//                             >
//                                 {categories.map((cat, index) => (
//                                     <MenuItem key={index} value={cat}>
//                                         {cat}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl> */}
//                         <FormControl fullWidth sx={{ maxWidth: 500, mb: 4 }}>
//                             <InputLabel>Category</InputLabel>
//                             <Select
//                                 label="Category"
//                                 value={selectedCategory}  // This is bound to the selectedCategory state
//                                 onChange={handleCategorySelect}  // Updates the selectedCategory state on selection
//                             >
//                                 {categories.length > 0 ? (
//                                     categories.map((cat, index) => (  // Categories are now unique
//                                         <MenuItem key={index} value={cat}>
//                                             {cat}
//                                         </MenuItem>
//                                     ))
//                                 ) : (
//                                     <MenuItem disabled>No categories available</MenuItem> // Fallback if categories are empty
//                                 )}
//                             </Select>
//                         </FormControl>





//                         {/* Display Greetings */}
//                         {/* <Grid container spacing={2}>
//                             {greetings.length > 0 ? (
//                                 greetings.map((greeting, index) => (
//                                     <Grid item xs={12} sm={6} md={4} key={index}>
//                                         <Card>
//                                             <CardMedia
//                                                 component="img"
//                                                 alt={greeting.category}
//                                                 height="350"


//                                                 image={greeting.image_url}
//                                                 title={greeting.category}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="h6" color="textPrimary">
//                                                     {greeting.category}  
//                                                 </Typography>
//                                                 <Typography variant="body2" color="textSecondary">
//                                                     {greeting.message} 
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </Grid>
//                                 ))
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary" align="center">
//                                     No greetings available for the selected category.
//                                 </Typography>
//                             )}
//                         </Grid> */}

//                         <Grid container spacing={2}>
//                             {greetings.length > 0 ? (
//                                 greetings.map((greeting, index) => (
//                                     <Grid item xs={12} sm={6} md={4} key={index}>
//                                         <Card>
//                                             <CardMedia
//                                                 component="img"
//                                                 alt={greeting.category}
//                                                 height="350"
//                                                 image={greeting.image_url} // Ensure this is a valid URL for the image
//                                                 title={greeting.category}
//                                                 sx={{
//                                                     objectFit: 'contain', // Ensures image fits within the container
//                                                     width: '100%',        // Ensures the image takes full width of the container
//                                                     height: 'auto'        // Maintain the aspect ratio of the image
//                                                 }}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="h6" color="textPrimary">
//                                                     {greeting.category}
//                                                 </Typography>
//                                                 <Typography variant="body2" color="textSecondary">
//                                                     {greeting.message}
//                                                 </Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </Grid>
//                                 ))
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary" align="center">
//                                     No greetings available for the selected category.
//                                 </Typography>
//                             )}
//                         </Grid>


//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Greetings;




// import React, { useState, useEffect } from 'react';
// import { Container, Box, TextField, Button, Grid, Typography, Card, CardMedia, CardContent, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { useNavigate } from 'react-router-dom';
// import { Col, Row } from 'react-bootstrap';
// import { FaCamera } from "react-icons/fa";

// const Greetings = () => {
//     const navigate = useNavigate();
//     const [uploadCategory, setUploadCategory] = useState('');
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [categories, setCategories] = useState([]);
//     const [greetings, setGreetings] = useState([]);
//     const userId = sessionStorage.getItem('userId');

//     // Fetch available categories
//     const fetchCategories = async () => {
//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/get/greetings');
//             const data = await response.json();

//             // Filter categories based on userId
//             const userCategories = data.filter(item => item.userId === userId);

//             // Remove duplicate categories
//             const uniqueCategories = userCategories
//                 .map(item => item.category)
//                 .filter((value, index, self) => self.indexOf(value) === index);

//             setCategories(uniqueCategories);
//         } catch (err) {
//             console.error('Error fetching categories:', err);
//         }
//     };

//     // Fetch images based on category and userId
//     const fetchImages = async (category) => {
//         try {
//             const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
//             const data = await response.json();
//             setImages(data?.data ?? []);
//         } catch (err) {
//             console.error('Error fetching images:', err);
//         }
//     };

//     // Fetch greetings for the selected category and userId
//     const fetchGreetings = async (category) => {
//         try {
//             const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
//             const data = await response.json();

//             // Filter greetings by userId
//             const userGreetings = data.filter(item => item.userId === userId);
//             setGreetings(userGreetings);
//         } catch (err) {
//             console.error('Error fetching greetings:', err);
//         }
//     };

//     const [previewUrl, setPreviewUrl] = useState(null);

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             setImage(file);
//             setPreviewUrl(URL.createObjectURL(file)); // Create a URL for previewing the image
//         }
//     };

//     const handleUpload = async () => {
//         if (!uploadCategory || !image) {
//             setMessage('Please select a category and an image.');
//             setOpenSnackbar(true);
//             return;
//         }

//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', uploadCategory);

//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchImages(uploadCategory);
//             fetchGreetings(uploadCategory);
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setImage("");
//             setPreviewUrl("");
//             setUploadCategory("");
//             setLoading(false);
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//         }
//     };

//     const handleCategoryChange = (e) => {
//         setUploadCategory(e.target.value);
//     };

//     const handleCategorySelect = (e) => {
//         setSelectedCategory(e.target.value);
//         fetchGreetings(e.target.value);
//     };

//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     useEffect(() => {
//         fetchCategories();
//     }, []);

//     useEffect(() => {
//         if (selectedCategory) {
//             fetchGreetings(selectedCategory);
//         }
//     }, [selectedCategory]);

//     return (
//         <div className="dashboard">
//             <Row>
//                 <Col>
//                     <div className="sidebar">
//                         <h3>Dashboard</h3>
//                         <ul>
//                             <li><button className="buttonA" onClick={() => window.location.href = '/Dashboard'}>Posts</button></li>
//                             <li><button className="buttonA" onClick={() => window.location.href = '/greetings'}>Greetings</button></li>
//                             <li><button className="buttonA" onClick={handleLogout}>LogOut</button></li>
//                         </ul>
//                     </div>
//                 </Col>
//                 <Col>
//                     <div className="content" maxWidth="md" sx={{ mt: 4 }}>
//                         <Typography variant="h4" gutterBottom align="center" color="primary">
//                             Greeting Image Upload
//                         </Typography>
// <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
//     <TextField
//         label="Category"
//         variant="outlined"
//         fullWidth
//         value={uploadCategory}
//         onChange={handleCategoryChange}
//         sx={{ maxWidth: 500 }}
//     />
//     <Button variant="contained" component="label" fullWidth sx={{ maxWidth: 200 }}>
//         <FaCamera /> Upload Image
//         <input type="file" hidden onChange={handleImageChange} />
//     </Button>
//     {previewUrl && (
//         <Box sx={{ mt: 2, textAlign: 'center' }}>
//             <Typography variant="body1" color="textSecondary">Image Preview:</Typography>
//             <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '10px' }} />
//         </Box>
//     )}
//     <LoadingButton
//         variant="contained"
//         onClick={handleUpload}
//         loading={loading}
//         fullWidth
//         sx={{ maxWidth: 500 }}
//     >
//         Upload
//     </LoadingButton>
// </Box>

//                         <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
//                             <Alert onClose={() => setOpenSnackbar(false)} severity={message === 'Error uploading image' ? 'error' : 'success'}>
//                                 {message}
//                             </Alert>
//                         </Snackbar>

//                         <FormControl fullWidth sx={{ maxWidth: 500, mb: 4 }}>
//                             <InputLabel>Category</InputLabel>
//                             <Select
//                                 label="Category"
//                                 value={selectedCategory}
//                                 onChange={handleCategorySelect}
//                             >
//                                 {categories.length > 0 ? (
//                                     categories.map((cat, index) => (
//                                         <MenuItem key={index} value={cat}>{cat}</MenuItem>
//                                     ))
//                                 ) : (
//                                     <MenuItem disabled>No categories available</MenuItem>
//                                 )}
//                             </Select>
//                         </FormControl>

//                         <Grid container spacing={2}>
//                             {greetings.length > 0 ? (
//                                 greetings.map((greeting, index) => (
//                                     <Grid item xs={12} sm={6} md={4} key={index}>
//                                         <Card>
//                                             <CardMedia
//                                                 component="img"
//                                                 alt={greeting.category}
//                                                 height="350"
//                                                 image={greeting.image_url}
//                                                 title={greeting.category}
//                                                 sx={{
//                                                     objectFit: 'contain',
//                                                     width: '100%',
//                                                     height: 'auto',
//                                                 }}
//                                             />
//                                             <CardContent>
//                                                 <Typography variant="h6" color="textPrimary">{greeting.category}</Typography>
//                                                 <Typography variant="body2" color="textSecondary">{greeting.message}</Typography>
//                                             </CardContent>
//                                         </Card>
//                                     </Grid>
//                                 ))
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary" align="center">
//                                     No greetings available for the selected category.
//                                 </Typography>
//                             )}
//                         </Grid>
//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Greetings;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCamera } from "react-icons/fa";
import { Col, Row } from 'react-bootstrap';

// Custom CSS import
import './Greetings.css'; // Make sure to import the provided CSS here

const Greetings = () => {
    const navigate = useNavigate();
    const [uploadCategory, setUploadCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [categories, setCategories] = useState([]);
    const [greetings, setGreetings] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [openModal, setOpenModal] = useState(false); // To control modal visibility
    const userId = sessionStorage.getItem('userId');

    // Fetch available categories
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://lionsnewsapi.emedha.in/admin/get/greetings');
            const data = await response.json();

            const userCategories = data.filter(item => item.userId === userId);
            const uniqueCategories = userCategories
                .map(item => item.category)
                .filter((value, index, self) => self.indexOf(value) === index);

            setCategories(uniqueCategories);
        } catch (err) {
            console.error('Error fetching categories:', err);
        }
    };

    // Fetch greetings for the selected category and userId
    const fetchGreetings = async (category) => {
        try {
            const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
            const data = await response.json();

            const userGreetings = data.filter(item => item.userId === userId);
            setGreetings(userGreetings);
        } catch (err) {
            console.error('Error fetching greetings:', err);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpload = async () => {
        // if (!image) {
        //   setMessage('Please select a category and an image.');
        //   setOpenSnackbar(true);
        //   return;
        // }

        setLoading(true);
        const formData = new FormData();
        formData.append('image', image);
        formData.append('userId', userId);
        formData.append('category', uploadCategory);

        try {
            const response = await fetch('https://lionsnewsapi.emedha.in/admin/upload', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            fetchGreetings(uploadCategory);
            setMessage(data.message);
            setOpenSnackbar(true);
            setImage(null);
            setPreviewUrl(null);
            setUploadCategory('');
            setLoading(false);
            setOpenModal(false); // Close the modal after successful upload
        } catch (err) {
            setMessage('Error uploading image');
            setOpenSnackbar(true);
            setLoading(false);
        }
    };

    const handleCategoryChange = (e) => {
        setUploadCategory(e.target.value);
    };

    const handleCategorySelect = (e) => {
        setSelectedCategory(e.target.value);
        fetchGreetings(e.target.value);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            fetchGreetings(selectedCategory);
        }
    }, [selectedCategory]);

    return (
        <div className="dashboard-layout">
            <div className="sidebar-nav">
                <h2>Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/Dashboard'}>Posts</button></li>
                    {/* <li><button className="side-button" onClick={handleArchiveClick}>Archive</button></li> */}
                    <li><button className="side-button" onClick={() => window.location.href = '/greetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/profile'}>
                        Profile
                    </button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="content-section">
                <h1 className="page-title">Greeting Image Upload</h1>

                {/* Button to open the modal */}
                <button
                    className="main-button"
                    onClick={() => setOpenModal(true)}
                >
                    Send Greetings
                </button>

                {/* Modal Dialog */}
                {openModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Upload Greeting Image</h2>
                            <div className="filter-section">
                                <input
                                    type="text"
                                    placeholder="Category"
                                    value={uploadCategory}
                                    onChange={handleCategoryChange}
                                />

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {previewUrl && (
                                    <div className="preview-area">
                                        <img src={previewUrl} alt="Preview" />
                                    </div>
                                )}
                                <button className="side-button" onClick={handleUpload} disabled={loading}>
                                    {loading ? 'Uploading...' : 'Upload Greeting Image'}
                                </button>
                            </div>


                            <button onClick={() => setOpenModal(false)} className="cancel-btn">Cancel</button>
                        </div>
                    </div>
                )}

                {/* Snackbar for feedback */}
                {openSnackbar && (
                    <div className="snackbar-notification">
                        <span>{message}</span>
                        <button onClick={() => setOpenSnackbar(false)}>Close</button>
                    </div>
                )}

                {/* Category Select */}
                <div className="filter-section">
                    <select
                        value={selectedCategory}
                        onChange={handleCategorySelect}
                    >
                        <option value="" disabled>
                            Select category
                        </option>
                        {categories.length > 0 ? (
                            categories.map((cat, index) => (
                                <option key={index} value={cat}>{cat}</option>
                            ))
                        ) : (
                            <option disabled>No categories available</option>
                        )}
                    </select>
                </div>

                {/* Greetings Cards */}
                <div className="card-container">
                    {greetings.length > 0 ? (
                        greetings.map((greeting, index) => (
                            <div key={index} className="card-item">
                                <img
                                    src={greeting.image_url}
                                    alt={greeting.category}
                                    className="card-item-image"
                                />
                                <div className="card-item-body">
                                    <h2>{greeting.category}</h2>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No greetings available for the selected category.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Greetings;



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import html2canvas from 'html2canvas';
// import { FaCamera } from "react-icons/fa";
// import { Col, Row } from 'react-bootstrap';
// import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon } from 'react-share'; // Import react-share components

// // Custom CSS import
// import './Greetings.css';

// const Greetings = () => {
//     const navigate = useNavigate();
//     const [uploadCategory, setUploadCategory] = useState('');
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [image, setImage] = useState(null);
//     const [message, setMessage] = useState('');
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [categories, setCategories] = useState([]);
//     const [greetings, setGreetings] = useState([]);
//     const [previewUrl, setPreviewUrl] = useState(null);
//     const [openModal, setOpenModal] = useState(false); // To control modal visibility
//     const userId = sessionStorage.getItem('userId');

//     // Fetch available categories
//     const fetchCategories = async () => {
//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/get/greetings');
//             const data = await response.json();

//             const userCategories = data.filter(item => item.userId === userId);
//             const uniqueCategories = userCategories
//                 .map(item => item.category)
//                 .filter((value, index, self) => self.indexOf(value) === index);

//             setCategories(uniqueCategories);
//         } catch (err) {
//             console.error('Error fetching categories:', err);
//         }
//     };

//     // Fetch greetings for the selected category and userId
//     const fetchGreetings = async (category) => {
//         try {
//             const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
//             const data = await response.json();

//             const userGreetings = data.filter(item => item.userId === userId);
//             setGreetings(userGreetings);
//         } catch (err) {
//             console.error('Error fetching greetings:', err);
//         }
//     };

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             setImage(file);
//             setPreviewUrl(URL.createObjectURL(file));
//         }
//     };

//     const handleUpload = async () => {
//         setLoading(true);
//         const formData = new FormData();
//         formData.append('image', image);
//         formData.append('userId', userId);
//         formData.append('category', uploadCategory);

//         try {
//             const response = await fetch('https://lionsnewsapi.emedha.in/admin/upload', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             fetchGreetings(uploadCategory);
//             setMessage(data.message);
//             setOpenSnackbar(true);
//             setImage(null);
//             setPreviewUrl(null);
//             setUploadCategory('');
//             setLoading(false);
//             setOpenModal(false); // Close the modal after successful upload
//         } catch (err) {
//             setMessage('Error uploading image');
//             setOpenSnackbar(true);
//             setLoading(false);
//         }
//     };

//     const handleCategoryChange = (e) => {
//         setUploadCategory(e.target.value);
//     };

//     const handleCategorySelect = (e) => {
//         setSelectedCategory(e.target.value);
//         fetchGreetings(e.target.value);
//     };

//     const handleLogout = () => {
//         sessionStorage.removeItem('userId');
//         sessionStorage.removeItem('userData');
//         sessionStorage.removeItem('authToken');
//         sessionStorage.removeItem('userDistrict');
//         navigate('/');
//     };

//     useEffect(() => {
//         fetchCategories();
//     }, []);

//     useEffect(() => {
//         if (selectedCategory) {
//             fetchGreetings(selectedCategory);
//         }
//     }, [selectedCategory]);

//     // Function to take a screenshot of the card
//     const handleShare = (greetingId) => {
//         const greetingCard = document.getElementById(`greeting-card-${greetingId}`);

//         html2canvas(greetingCard).then(canvas => {
//             const imageData = canvas.toDataURL('image/png');  // Get the screenshot image

//             // Set the imageData for sharing
//             setPreviewUrl(imageData);  // Set the image as preview for sharing
//         });
//     };

//     return (
//         <div className="dashboard-layout">
//             <div className="sidebar-nav">
//                 <h2>Dashboard</h2>
//                 <ul>
//                     <li><button className="side-button" onClick={() => window.location.href = '/Dashboard'}>Posts</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/greetings'}>Greetings</button></li>
//                     <li><button className="side-button" onClick={() => window.location.href = '/profile'}>Profile</button></li>
//                     <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
//                 </ul>
//             </div>

//             <div className="content-section">
//                 <h1 className="page-title">Greeting Image Upload</h1>
//                 <button className="main-button" onClick={() => setOpenModal(true)}>Send Greetings</button>

//                 {openModal && (
//                     <div className="modal-overlay">
//                         <div className="modal-content">
//                             <h2>Upload Greeting Image</h2>
//                             <div className="filter-section">
//                                 <input
//                                     type="text"
//                                     placeholder="Category"
//                                     value={uploadCategory}
//                                     onChange={handleCategoryChange}
//                                 />
//                                 <input
//                                     type="file"
//                                     accept="image/*"
//                                     onChange={handleImageChange}
//                                 />
//                                 <button className="side-button" onClick={handleUpload} disabled={loading}>
//                                     {loading ? 'Uploading...' : 'Upload Image'}
//                                 </button>
//                             </div>
//                             {previewUrl && (
//                                 <div className="preview-area">
//                                     <img src={previewUrl} alt="Preview" />
//                                 </div>
//                             )}
//                             <button onClick={() => setOpenModal(false)} className="cancel-btn">Cancel</button>
//                         </div>
//                     </div>
//                 )}

//                 {openSnackbar && (
//                     <div className="snackbar-notification">
//                         <span>{message}</span>
//                         <button onClick={() => setOpenSnackbar(false)}>Close</button>
//                     </div>
//                 )}

//                 <div className="filter-section">
//                     <select value={selectedCategory} onChange={handleCategorySelect}>
//                         <option value="" disabled>Select category</option>
//                         {categories.length > 0 ? (
//                             categories.map((cat, index) => (
//                                 <option key={index} value={cat}>{cat}</option>
//                             ))
//                         ) : (
//                             <option disabled>No categories available</option>
//                         )}
//                     </select>
//                 </div>

//                 <div className="card-container">
//                     {greetings.length > 0 ? (
//                         greetings.map((greeting, index) => (
//                             <div key={index} id={`greeting-card-${greeting.id}`} className="card-item">
//                                 <img
//                                     src={greeting.image_url}
//                                     alt={greeting.category}
//                                     className="card-item-image"
//                                 />
//                                 <div className="card-item-body">
//                                     <h2>{greeting.category}</h2>
//                                     <button onClick={() => handleShare(greeting.id)}>Share</button>

//                                     {/* Social Media Share Buttons */}
//                                     {previewUrl && (
//                                         <div className="social-share-buttons">
//                                             <FacebookShareButton url={previewUrl} quote="Check out this greeting!" hashtag="#greetings">
//                                                 <FacebookIcon size={32} round />
//                                             </FacebookShareButton>
//                                             <TwitterShareButton url={previewUrl} title="Check out this greeting!">
//                                                 <TwitterIcon size={32} round />
//                                             </TwitterShareButton>
//                                             <WhatsappShareButton url={previewUrl} title="Check out this greeting!">
//                                                 <WhatsappIcon size={32} round />
//                                             </WhatsappShareButton>
//                                             <EmailShareButton subject="Check out this greeting" body="I wanted to share this greeting with you:">
//                                                 <EmailIcon size={32} round />
//                                             </EmailShareButton>
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         ))
//                     ) : (
//                         <p>No greetings available for the selected category.</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Greetings;
