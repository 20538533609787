import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [archivedPosts, setArchivedPosts] = useState([]); // Archived posts
  const [showArchive, setShowArchive] = useState(false); // To toggle between posts and archive
  const [searchCategory, setSearchCategory] = useState(""); // Search input for category
  const [selectedDate, setSelectedDate] = useState(''); // Date filter
  const [allDates, setAllDates] = useState([]); // Store unique dates

  const [showMonthPopup, setShowMonthPopup] = useState(false); // Show popup for month selection
  const [selectedMonth, setSelectedMonth] = useState(''); // Store selected month
  const [selectedPostId, setSelectedPostId] = useState(null); // Store the post ID to be archived
  const [selectedPostApproveId, setSelectedPostApproveId] = useState(null); // Store post ID for approval

  const [showMonthFilter, setShowMonthFilter] = useState(false); // To toggle month filter visibility

  // Fetch the district from sessionStorage
  const userDistrict = sessionStorage.getItem('userDistrict');

  useEffect(() => {
    // Fetch data from the backend API
    fetch('https://lionsnewsapi.emedha.in/api/otp/get/posts')
      .then((response) => response.json())
      .then((data) => {
        // Filter posts based on district
        //const filteredPosts = data.filter(post => post.district === userDistrict);

        // Separate unarchived and archived posts after district filter
        const unarchivedPosts = data.filter(post => post.isArchived === 0);
        const archivedPosts = data.filter(post => post.isArchived === 1);

        setPosts(unarchivedPosts);
        setArchivedPosts(archivedPosts);
        setLoading(false); // Set loading to false once data is loaded

        // Extract all unique dates from the filtered posts
        const dates = [...new Set(data.map(post => new Date(post.createdAt).toLocaleDateString('en-CA')))];

        setAllDates(dates);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setLoading(false); // Handle error case
      });
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  // Filter posts by category
  const handleSearch = (event) => {
    setSearchCategory(event.target.value);
  };

  // Filter posts based on date
  const handleDateFilterChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleMonthFilterChange = (event) => {
    setSelectedMonth(event.target.value); // Update the selected month
  };




  const filterPostsByMonth = (posts, selectedMonth, selectedDate) => {
    //   if (!selectedMonth = (posts, selectedMonth, selectedDate) => {
    return posts.filter(post => {
      const postMonth = post.month; // Get the month of the post
      const postDate = new Date(post.createdAt).toLocaleDateString('en-CA'); // Convert the date to a standard format

      // Filter by both month and date
      const matchesMonth = selectedMonth ? postMonth === selectedMonth : true;
      const matchesDate = selectedDate ? postDate === selectedDate : true;

      return matchesMonth && matchesDate;
    });
  };


  // Filter posts by category or email and date
  const filterPostsByCategoryEmailAndDate = (posts) => {
    return posts.filter((post) => {
      const isCategoryMatch = post.category?.toLowerCase().includes(searchCategory.toLowerCase());
      const isEmailMatch = post.email?.toLowerCase().includes(searchCategory.toLowerCase());
      const isDateMatch = selectedDate ? new Date(post.createdAt).toLocaleDateString('en-CA') === selectedDate : true;
      return (isCategoryMatch || isEmailMatch) && isDateMatch;
    });
  };

  // Sort posts by date, today first
  const sortedPosts = filterPostsByCategoryEmailAndDate(posts).sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return bDate - aDate; // Sort descending by date
  });

 
  const sortedArchivedPosts = filterPostsByMonth(archivedPosts, selectedMonth).sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return bDate - aDate;
  });


  const handleLogout = () => {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('adminEmail');
    sessionStorage.removeItem('userDistrict');
    navigate('/');
  };

  const handlePostsClick = () => setShowArchive(false);
  const handleArchiveClick = () => setShowArchive(true);

  // Approve a post
  const handleApprove = (id) => {
    fetch(`https://lionsnewsapi.emedha.in/api/otp/status/approve/${id}`, {
      method: 'PUT',
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.map((post) =>
          post.id === id ? { ...post, status: 'approved' } : post
        ));
      })
      .catch((error) => {
        console.error('Error approving post:', error);
      });
  };

  const handleArchive = (id) => {
    setShowMonthPopup(true); // Show the popup to select month
    setSelectedPostId(id); // Store the selected post's ID
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleSubmitMonth = () => {
    if (!selectedMonth) {
      alert('Please select a month');
      return;
    }

    fetch(`https://lionsnewsapi.emedha.in/api/otp/archive/${selectedPostId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        month: selectedMonth, // Send the selected month
      }),
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.filter(post => post.id !== selectedPostId)); // Remove from active posts
        const archivedPost = posts.find(post => post.id === selectedPostId);
        setArchivedPosts([...archivedPosts, { ...archivedPost, month: selectedMonth }]); // Add to archived posts
        setShowMonthPopup(false); // Close the popup
      })
      .catch((error) => {
        console.error('Error archiving post:', error);
      });
  };

  const handleUnarchive = (id) => {
    const postToUnarchive = archivedPosts.find((post) => post.id === id); // Find the post in archived
    setArchivedPosts(archivedPosts.filter((post) => post.id !== id)); // Remove from archived posts
    setPosts([...posts, postToUnarchive]); // Add it back to active posts

    fetch(`https://lionsnewsapi.emedha.in/api/otp/unarchive/${id}`, {
      method: 'PUT',
    })
      .then((response) => response.text())
      .catch((error) => {
        console.error('Error unarchiving post:', error);
      });
  };

  const handleDelete = (id) => {
    fetch(`https://lionsnewsapi.emedha.in/api/otp/delete/posts/${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.text())
      .then(() => {
        setPosts(posts.filter((post) => post.id !== id));
        setArchivedPosts(archivedPosts.filter((post) => post.id !== id));
      })
      .catch((error) => {
        console.error('Error deleting post:', error);
      });
  };

  return (
    <div className="dashboard">
      <div className="sidebar-nav">
        <h2>Admin Dashboard</h2>
        <ul>
          <li><button className="side-button" onClick={handlePostsClick}>Posts</button></li>
          <li><button className="side-button" onClick={handleArchiveClick}>Archive</button></li>
          {/* <li><button className="buttonA" onClick={handleGreetingsClick}>Greetings</button></li> */}
         <li> <button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>
            Greetings
          </button></li>
          <li> <button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>
            Admin's
          </button></li>
          <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
         <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>
            Profile
          </button></li>
          {/* <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>
            Posts
          </button></li> */}
          <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
          
        </ul>
      </div>

      <div className="content-section">
        {/* Filters Section */}
        <div className="filters">
          <input
            type="text"
            placeholder="Search by category"
            value={searchCategory}
            onChange={handleSearch}
          />
          {/* <select value={selectedDate} onChange={handleDateFilterChange}>
            <option value="">All Dates</option>
            {allDates.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select> */}
          <select value={selectedDate} onChange={handleDateFilterChange}>
            <option value="">All Dates</option>
            {allDates.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>


          {showArchive && (
            <select value={selectedMonth} onChange={handleMonthFilterChange}>
              <option value="">Select Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          )}

        </div>

        {/* Posts Section */}
        {!showArchive && (
          <div className="card-container">
            {sortedPosts.map((post) => (
              <div className="card" key={post.id}>
                <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
                <div className="card-body">
                  <h2>Text: {post.textInput}</h2>
                  <p>Category: {post.category || 'No category'}</p>
                  <p>Email: {post.email}</p>
                  <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
                    Status: {post.status}
                  </p>
                  <p>district : {post.district || "null"}</p>
                  <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

                  {post.status === 'pending' && (
                    <div className="actions">
                      <button className="buttonA" onClick={() => handleApprove(post.id)}>Accept</button>
                    </div>
                  )}

                  {post.status === 'approved' || post.status === 'rejected' ? (
                    <div className="actions">
                      <button className="buttonAr" onClick={() => handleArchive(post.id)}>Archive</button>
                    </div>
                  ) : null}

                  <div className="actions">
                    <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Archived Section */}
        {showArchive && (
          <div className="card-container">
            {filterPostsByMonth(sortedArchivedPosts, selectedMonth, selectedDate).map((post) => (
              <div className="card" key={post.id}>
                <img src={post.imageUri} alt={`Post ${post.id}`} className="card-image" />
                <div className="card-body">
                  <h2>Text: {post.textInput}</h2>
                  <p>Category: {post.category || 'No category'}</p>
                  <p>Email: {post.email}</p>
                  <p style={{ textTransform: 'uppercase', color: post.status === 'approved' ? 'green' : post.status === 'rejected' ? 'red' : 'black' }}>
                    Status: {post.status}
                  </p>
                  <p style={{ color: 'black' }}>Month : {post.month}</p>
                  <p>Date: {new Date(post.createdAt).toLocaleDateString('en-CA')}</p>

                  <div className="actions">
                  {/* <button className="buttonAr" onClick={() => handleUnarchive(post.id)}>Unarchive</button> */}
                    <button className="buttonDelete" onClick={() => handleDelete(post.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}


        {/* Month Popup */}
        {showMonthPopup && (
          <div className="month-popup">
            <div className="popup-content">
              <h3>Which Month you have to Archive the Post</h3>
              <select value={selectedMonth} onChange={handleMonthChange}>
                <option value="">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
              <button onClick={handleSubmitMonth}>Submit</button>
              <button onClick={() => setShowMonthPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
