// // src/components/ProtectedRoute.js
// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ element, ...rest }) => {
//   const isAuthenticated = sessionStorage.getItem('userId'); // Check if user is logged in

//   if (!isAuthenticated) {
//     // If not authenticated, redirect to login page
//     return <Navigate to="/" />;
//   }

//   return <Route {...rest} element={element} />;
// };

// export default ProtectedRoute;

// src/components/ProtectedRoute.js


// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ element, ...rest }) => {
//   const isAuthenticated = sessionStorage.getItem('userId'); // Check if user is logged in

//   if (!isAuthenticated) {
//     // If not authenticated, redirect to login page
//     return <Navigate to="/" />;
//   }

//   return element; // If authenticated, render the element passed
// };

// export default ProtectedRoute;


import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, isSuperAdminRequired = false, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem('userId'); // Check if user is logged in
  const userEmail = sessionStorage.getItem('adminEmail'); // Store user's email (or role) during login

  if (!isAuthenticated) {
    // If not authenticated, redirect to login page
    return <Navigate to="/" />;
  }

  if (isSuperAdminRequired && userEmail !== 'admin@gmail.com') {
    // If super admin route is required, but the user is not super admin, redirect to a different route
    return <Navigate to="/Dashboard" />; // Redirect non-super admins to user dashboard
  }

  return element; // If authenticated (and super admin route is not restricted or the user is a super admin), render the element passed
};

export default ProtectedRoute;
