import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleLogin = async () => {
    if (!email || !password) {
      alert('Please enter email and password');
      return;
    }
    if (email === 'admin@gmail.com' && password === 'admin@123') {

      sessionStorage.setItem('userId', 'superAdmin');
      sessionStorage.setItem('authToken', 'superAdminToken');
      sessionStorage.setItem('adminEmail', 'admin@gmail.com');
      console.log("Super admin login data:", {
        userId: sessionStorage.getItem('userId'),
        authToken: sessionStorage.getItem('authToken'),
        adminEmail: sessionStorage.getItem('adminEmail'),
      });

      alert('Super Admin Login Successful');
      navigate('/superAdminDashboard');
      return;
    }

    try {
      const response = await axios.post('https://lionsnewsapi.emedha.in/admin/login', { email, password });
      console.log('API Response:', response.data);

      if (response.status === 200) {
        const { token, user } = response.data;

        if (user) {
          // Store user data and userId in sessionStorage
          sessionStorage.setItem('userId', user.id); // Ensure user.id is correct
          sessionStorage.setItem('userData', JSON.stringify(user)); // Save entire user object
          sessionStorage.setItem('authToken', token);  // Store auth token as well
          sessionStorage.setItem('userDistrict', user.district);

          console.log('User authenticated, sessionStorage set');
          alert('Login Successful');
          // navigate('/Dashboard');  // Make sure this works as expected
          if (user.email !== 'admin@gmail.com') {
            navigate('/Dashboard');
          } else {
            alert('Admin cannot access the user dashboard.');
            // Optionally, you can navigate them to another route or stay on the login page
            navigate('/superAdminDashboard'); // Example redirect to super admin dashboard
          }
        }

        // Redirect to Dashboard after successful login

      }
    } catch (error) {
      console.error(error);
      alert('Login failed');
    }
  };


  return (
    <>
      <div style={styles.container}>
        {/* Login Form */}
        <div style={styles.formContainer}>
          <h1 style={styles.title}>Login</h1>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />

          <button style={styles.loginButton} onClick={handleLogin}>
            Login
          </button>
          <button
            style={styles.forgotPasswordLink}
            onClick={() => navigate('/signup')}
          >
            New Admin Account Creation
          </button>
          <button
            style={styles.forgotPasswordLink}
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '25px',
  },
  input: {
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '25px',
    fontSize: '16px',
    backgroundColor: '#f4f4f4',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#2A9D8F',
  },
  loginButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  loginButtonHover: {
    backgroundColor: '#1b7f70',
  },
  forgotPasswordLink: {
    marginTop: '15px',
    color: '#2A9D8F',
    fontSize: '14px',
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
  },
};

export default LoginScreen;
