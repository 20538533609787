import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Profile.css'; // Assuming you have a Profile.css for styling

const Profile = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const data = sessionStorage.getItem('userData');
        if (data) {
            setUserData(JSON.parse(data)); // Parse the stored JSON string
        }
    }, []);

    const adminEmail = sessionStorage.getItem('adminEmail');
    const authToken = sessionStorage.getItem('authToken');
    const userId = sessionStorage.getItem('userId');


    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
           sessionStorage.removeItem('adminEmail');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };

    return (
        <>
            <div className="sidebar-nav">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>Profile</button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="profile-content">
                <h1>Profile Details</h1>

                <div className="profile-info">
                    <h3>Super Admin Profile</h3>
                    <ul >
                        <li ><strong>Admin Email:</strong> {adminEmail || "Not available"}</li>
                        
                        <li ><strong>User ID:</strong> {userId || "Not available"}</li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Profile;
