import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Profile.css'; // Assuming you have a Profile.css for styling

const Profile = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const data = sessionStorage.getItem('userData');
        if (data) {
            setUserData(JSON.parse(data)); // Parse the stored JSON string
        }
    }, []);

    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };

    return (
        <>
            <div className="sidebar-nav">
                <h2>Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/Dashboard'}>Posts</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/greetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/profile'}>Profile</button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="profile-content">
                <h1>Profile Details</h1>

                {userData ? (
                    <div className="profile-info">
                        <ul>
                            <li><strong>Name:</strong> {userData.name}</li>
                            <li><strong>Email:</strong> {userData.email}</li>
                        
                            <li><strong>District:</strong> {userData.district}</li>
                            {/* Add other fields as needed */}
                        </ul>
                    </div>
                ) : (
                    <p>Loading profile data...</p>
                )}
            </div>
        </>
    );
}

export default Profile;
