// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

// import React from 'react';
// import Dashboard from './Components/AdminDashboard/Dashboard'; // Assuming the Dashboard component is in the same folder

// const App = () => {
//   //const userId = 10; // Pass the appropriate userId
//   return (
//     <div>
//       <Dashboard/>
//     </div>
//   );
// };

// export default App;

// src/App.js



// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


// import Dashboard from './Components/AdminDashboard/Dashboard';
// import LoginPage from './Components/Auth/Login';
// import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
// import Signup from './Components/Auth/Signup';
// import ForgotPassword from './Components/Auth/ForgotPassword';
// import ResetPassword from './Components/Auth/ResetPassword';
// import OtpVerification from './Components/Auth/OtpVerification';
// import UserResetPassword from './Components/Auth/UserResetPassword';
// import Greetings from './Components/AdminDashboard/Greetings';
// import Profile from './Components/AdminDashboard/Profile';

// import AdminDashboard from './Components/SuperAdminDashboard/AdminDashboard/AdminDashboard';
// import AdminGreetings from './Components/SuperAdminDashboard/AdminDashboard/AdminGreetings';
// import Admins from './Components/SuperAdminDashboard/AdminDashboard/Admins';
// import AdminUsers from './Components/SuperAdminDashboard/AdminDashboard/AdminUsers';
// import AdminProfile from './Components/SuperAdminDashboard/AdminDashboard/AdminProfile';
// import AdminPost from './Components/SuperAdminDashboard/AdminDashboard/AdminPost';
// import { useNavigate } from 'react-router-dom';

// const App = () => {


//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<LoginPage />} />
//         <Route path="/signup" element={<Signup />} />
//         <Route path="/otp-form" element={<OtpVerification />} />

//         <Route path="/forgot-password" element={<ForgotPassword />} />

//         <Route path="/reset-password" element={<ResetPassword />} />
//         <Route path="/reset-password/:resetToken" element={<ResetPassword />} />


//         <Route path="/resetpassword" element={<UserResetPassword />} />
//         <Route path="/resetpassword/:resetToken" element={<UserResetPassword />} />

//         {/* <Route path="/Dashboard"OtpVerification element={<Dashboard />} />  ForgotPassword*/}
//         <Route
//           path="/Dashboard"
//           element={
//             <ProtectedRoute element={<Dashboard />} />} />
//         <Route
//           path="/greetings"
//           element={
//             <ProtectedRoute element={<Greetings />} />} />
//         <Route
//           path="/profile"
//           element={
//             <ProtectedRoute element={<Profile />} />} />




//              <Route path="/superAdminDashboard" element={<AdminDashboard />} />
        
//         <Route path="/superAdminDashboardGreetings" element={<AdminGreetings />} />
//         <Route path="/superAdminAdminUsers" element={<Admins />} />
//         <Route path="/superAdminUsers" element={<AdminUsers />} />
      

//         <Route path="/superAdminDashboardProfile" element={<AdminProfile />} />


// {/* <Route
//           path="/superAdminDashboard"
//           element={isSuperAdmin ? <AdminDashboard /> : <RedirectToDashboard />}
//         />
//         <Route
//           path="/superAdminDashboardGreetings"
//           element={isSuperAdmin ? <AdminGreetings /> : <RedirectToDashboard />}
//         />
//         <Route
//           path="/superAdminAdminUsers"
//           element={isSuperAdmin ? <Admins /> : <RedirectToDashboard />}
//         />
//         <Route
//           path="/superAdminUsers"
//           element={isSuperAdmin ? <AdminUsers /> : <RedirectToDashboard />}
//         />
//         <Route
//           path="/superAdminDashboardProfile"
//           element={isSuperAdmin ? <AdminProfile /> : <RedirectToDashboard />}
//         />
//         <Route
//           path="/superAdminPost"
//           element={isSuperAdmin ? <AdminPost /> : <RedirectToDashboard />}
//         /> */}
//       </Routes>

//     </Router>
//   );
// };

// // const RedirectToDashboard = () => {
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     // Redirect to Dashboard if not super admin
// //     navigate('/Dashboard');
// //   }, [navigate]);

// //   return null; // You can return a loading state here if needed
// // };

// export default App;





import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from './Components/AdminDashboard/Dashboard';
import LoginPage from './Components/Auth/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import Signup from './Components/Auth/Signup';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import OtpVerification from './Components/Auth/OtpVerification';
import UserResetPassword from './Components/Auth/UserResetPassword';
import Greetings from './Components/AdminDashboard/Greetings';
import Profile from './Components/AdminDashboard/Profile';

import AdminDashboard from './Components/SuperAdminDashboard/AdminDashboard/AdminDashboard';
import AdminGreetings from './Components/SuperAdminDashboard/AdminDashboard/AdminGreetings';
import Admins from './Components/SuperAdminDashboard/AdminDashboard/Admins';
import AdminUsers from './Components/SuperAdminDashboard/AdminDashboard/AdminUsers';
import AdminProfile from './Components/SuperAdminDashboard/AdminDashboard/AdminProfile';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/otp-form" element={<OtpVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route path="/resetpassword" element={<UserResetPassword />} />
        <Route path="/resetpassword/:resetToken" element={<UserResetPassword />} />

        {/* User routes */}
        <Route path="/Dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/greetings" element={<ProtectedRoute element={<Greetings />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />

        {/* Super Admin routes */}
        <Route 
          path="/superAdminDashboard" 
          element={<ProtectedRoute element={<AdminDashboard />} isSuperAdminRequired={true} />} />
        <Route 
          path="/superAdminDashboardGreetings" 
          element={<ProtectedRoute element={<AdminGreetings />} isSuperAdminRequired={true} />} />
        <Route 
          path="/superAdminAdminUsers" 
          element={<ProtectedRoute element={<Admins />} isSuperAdminRequired={true} />} />
        <Route 
          path="/superAdminUsers" 
          element={<ProtectedRoute element={<AdminUsers />} isSuperAdminRequired={true} />} />
        <Route 
          path="/superAdminDashboardProfile" 
          element={<ProtectedRoute element={<AdminProfile />} isSuperAdminRequired={true} />} />
      </Routes>
    </Router>
  );
};

export default App;
