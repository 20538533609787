import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCamera } from "react-icons/fa";
import { Col, Row } from 'react-bootstrap';

import './Greetings.css';

const Greetings = () => {
    const navigate = useNavigate();
    const [uploadCategory, setUploadCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [categories, setCategories] = useState([]);
    const [greetings, setGreetings] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [openModal, setOpenModal] = useState(false); // To control modal visibility
     const userId = sessionStorage.getItem('userId');

    // Fetch available categories
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://lionsnewsapi.emedha.in/admin/get/greetings');
            const data = await response.json();

         
            const uniqueCategories = data
            .map(item => item.category)
            .filter((value, index, self) => self.indexOf(value) === index);

            setCategories(uniqueCategories);
        } catch (err) {
            console.error('Error fetching categories:', err);
        }
    };

    // Fetch greetings for the selected category and userId
    const fetchGreetings = async (category) => {
        try {
            const response = await fetch(`https://lionsnewsapi.emedha.in/admin/images/${category}`);
            const data = await response.json();

            //const userGreetings = data.filter(item => item.userId === userId);
            setGreetings(data);
        } catch (err) {
            console.error('Error fetching greetings:', err);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpload = async () => {
        // if (!image) {
        //   setMessage('Please select a category and an image.');
        //   setOpenSnackbar(true);
        //   return;
        // }

        setLoading(true);
        const formData = new FormData();
        formData.append('image', image);
        formData.append('userId', userId);
        formData.append('category', uploadCategory);

        try {
            const response = await fetch('https://lionsnewsapi.emedha.in/admin/upload', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            fetchGreetings(uploadCategory);
            setMessage(data.message);
            setOpenSnackbar(true);
            setImage(null);
            setPreviewUrl(null);
            setUploadCategory('');
            setLoading(false);
            setOpenModal(false); // Close the modal after successful upload
        } catch (err) {
            setMessage('Error uploading image');
            setOpenSnackbar(true);
            setLoading(false);
        }
    };

    const handleCategoryChange = (e) => {
        setUploadCategory(e.target.value);
    };

    const handleCategorySelect = (e) => {
        setSelectedCategory(e.target.value);
        fetchGreetings(e.target.value);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('adminEmail');
        sessionStorage.removeItem('userDistrict');
        navigate('/');
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            fetchGreetings(selectedCategory);
        }
    }, [selectedCategory]);

    return (
        <div className="dashboard-layout">
            <div className="sidebar-nav">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboard'}>Posts</button></li>
                    {/* <li><button className="side-button" onClick={handleArchiveClick}>Archive</button></li> */}
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardGreetings'}>Greetings</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminAdminUsers'}>Admin's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminUsers'}>Users's</button></li>
                    <li><button className="side-button" onClick={() => window.location.href = '/superAdminDashboardProfile'}>
                        Profile
                    </button></li>
                    <li><button className="side-button" onClick={handleLogout}>LogOut</button></li>
                </ul>
            </div>

            <div className="content-section">
                <h1 className="page-title">Greeting Image Upload</h1>

                {/* Button to open the modal */}
                <button
                    className="main-button"
                    onClick={() => setOpenModal(true)}
                >
                    Send Greetings
                </button>

                {/* Modal Dialog */}
                {openModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Upload Greeting Image</h2>
                            <div className="filter-section">
                                <input
                                    type="text"
                                    placeholder="Category"
                                    value={uploadCategory}
                                    onChange={handleCategoryChange}
                                />

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {previewUrl && (
                                    <div className="preview-area">
                                        <img src={previewUrl} alt="Preview" />
                                    </div>
                                )}
                                <button className="side-button" onClick={handleUpload} disabled={loading}>
                                    {loading ? 'Uploading...' : 'Upload Greeting Image'}
                                </button>
                            </div>


                            <button onClick={() => setOpenModal(false)} className="cancel-btn">Cancel</button>
                        </div>
                    </div>
                )}

                {/* Snackbar for feedback */}
                {openSnackbar && (
                    <div className="snackbar-notification">
                        <span>{message}</span>
                        <button onClick={() => setOpenSnackbar(false)}>Close</button>
                    </div>
                )}

                {/* Category Select */}
                <div className="filter-section">
                    <select
                        value={selectedCategory}
                        onChange={handleCategorySelect}
                    >
                        <option value="" disabled>
                            Select category
                        </option>
                        {categories.length > 0 ? (
                            categories.map((cat, index) => (
                                <option key={index} value={cat}>{cat}</option>
                            ))
                        ) : (
                            <option disabled>No categories available</option>
                        )}
                    </select>
                </div>

                {/* Greetings Cards */}
                <div className="card-container">
                    {greetings.length > 0 ? (
                        greetings.map((greeting, index) => (
                            <div key={index} className="card-item">
                                <img
                                    src={greeting.image_url}
                                    alt={greeting.category}
                                    className="card-item-image"
                                />
                                <div className="card-item-body">
                                    <h2>{greeting.category}</h2>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No greetings available for the selected category.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Greetings;


