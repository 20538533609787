// import React, { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';

// const UserResetPasswordScreen = () => {
//       const navigate = useNavigate();
    
//       const { resetToken } = useParams();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');

//   const handleResetPassword = async () => {
//     if (!password || !confirmPassword) {
//       alert('Please enter and confirm your new password');
//       return;
//     }

//     if (password !== confirmPassword) {
//       alert('Passwords do not match');
//       return;
//     }

//     try {
//       const response = await axios.post(`http://localhost:3000/api/otp/resetpassword/${resetToken}`, { password });
//       if (response.status === 200) {
//         alert('Your password has been reset successfully');     
//         navigate('/');
//       }
//     } catch (error) {
//       console.error(error);
//       alert(error.response?.data?.error || 'Failed to reset password');
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <div style={styles.formContainer}>
//         <h2 style={styles.title}>User Reset Password</h2>

//         <input
//           type="password"
//           placeholder="New Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           style={styles.input}
//         />

//         <input
//           type="password"
//           placeholder="Confirm Password"
//           value={confirmPassword}
//           onChange={(e) => setConfirmPassword(e.target.value)}
//           style={styles.input}
//         />

//         <button onClick={handleResetPassword} style={styles.resetButton}>
//           Reset Password
//         </button>

//         {/* <button onClick={navigateToLogin} style={styles.backLink}>
//           Back to Login
//         </button> */}
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '20px',
//     backgroundColor: '#f5f5f5',
//     height: '100vh',
//   },
//   formContainer: {
//     width: '100%',
//     maxWidth: '400px',
//     backgroundColor: '#fff',
//     borderRadius: '15px',
//     padding: '25px',
//     boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
//   },
//   title: {
//     fontSize: '32px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     textAlign: 'center',
//     marginBottom: '20px',
//   },
//   input: {
//     height: '50px',
//     borderColor: '#ddd',
//     borderWidth: '1px',
//     borderRadius: '25px',
//     marginBottom: '15px',
//     paddingLeft: '20px',
//     fontSize: '16px',
//     backgroundColor: '#f8f8f8',
//     width: '100%',
//   },
//   resetButton: {
//     backgroundColor: '#2A9D8F',
//     height: '50px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     borderRadius: '25px',
//     marginTop: '10px',
//     color: '#fff',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//   },
//   backLink: {
//     marginTop: '15px',
//     color: '#2A9D8F',
//     fontSize: '14px',
//     textAlign: 'center',
//     cursor: 'pointer',
//     border: 'none',
//     background: 'none',
//   },
// };

// export default UserResetPasswordScreen;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// Import an app icon for display
import appIcon from './lionssamcharapplogo.png'; // Ensure the image is in the correct path or use an external URL

const UserResetPasswordScreen = () => {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordReset, setPasswordReset] = useState(false); // New state to track password reset success

  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      alert('Please enter and confirm your new password');
      return;
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
//  https://lionsnewsapi.emedha.in
//  http://localhost:3000                https://lionsnewsapi.emedha.in/api/otp/resetpassword/3316517e-88dc-442d-abae-6fb7075b48c6
    try {
      const response = await axios.post(`https://lionsnewsapi.emedha.in/api/otp/resetpassword/${resetToken}`, { password });
      if (response.status === 200) {
        alert('Your password has been reset successfully');
        setPasswordReset(true); // Set password reset success to true
      }
    } catch (error) {

      console.error(error);
      alert(error.response?.data?.error || 'Failed to reset password');
    }
  };

  return (
    <div style={styles.container}>
      {!passwordReset ? (
        <div style={styles.formContainer}>
          <h2 style={styles.title}>User Reset Password</h2>

          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />

          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={styles.input}
          />

          <button onClick={handleResetPassword} style={styles.resetButton}>
            Reset Password
          </button>
        </div>
      ) : (
        <div style={styles.successCard}>
          <img src={appIcon} alt="App Icon" style={styles.appIcon} />
          <h2 style={{color:'#2A9D8F'}}>Your password has been updated!</h2>
          <h3 style={{color:"black"}}>Go ahead and login to your Account.</h3>
         
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    height: '100vh',
  },
  formContainer: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '25px',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '20px',
  },
  input: {
    height: '50px',
    borderColor: '#ddd',
    borderWidth: '1px',
    borderRadius: '25px',
    marginBottom: '15px',
    paddingLeft: '20px',
    fontSize: '16px',
    backgroundColor: '#f8f8f8',
    width: '100%',
  },
  resetButton: {
    backgroundColor: '#2A9D8F',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    marginTop: '10px',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
  },
  successCard: {
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '25px',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
  },
  appIcon: {
    width: '190px',
    height: '200px',
    marginBottom: '20px',
  },
  loginButton: {
    backgroundColor: '#2A9D8F',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    marginTop: '10px',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
  },
};

export default UserResetPasswordScreen;
